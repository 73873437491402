import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  /* Center content both vertically and horizontally */
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.5);

  z-index: 50;
`;

export const ModalContent = styled.div`
  position: relative;
  max-height: 90vh;
  min-width: 25rem;
  overflow-y: auto;
`;
