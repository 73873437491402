import { RawAPIUpdatedEntities, APIUpdatedEntities } from '../../types/api';
import { mapRawUpdatedEntities } from '../../types/mappers';

import { ExtractActionTypes, makeAction } from '../../utils/actionCreators';
import {
  BackendError,
  apiErrorHandlingWithDecode,
  POST,
} from '../../utils/api';
import { createAsyncThunk } from '../../utils/thunk';

const actionCreators = {
  ...makeAction('putInvoiceHeaderComplaintStarted')<{
    purchaseInvoiceHeaderId: string;
  }>(),
  ...makeAction('putInvoiceHeaderComplaintSuccess')<
    { purchaseInvoiceHeaderId: string } & APIUpdatedEntities
  >(),
  ...makeAction('putInvoiceHeaderComplaintFailure')<{
    purchaseInvoiceHeaderId: string;
    error: BackendError | undefined;
  }>(),
};

export type PurchaseInvoiceReclamation = ExtractActionTypes<
  typeof actionCreators
>;

export const {
  putInvoiceHeaderComplaintStarted,
  putInvoiceHeaderComplaintSuccess,
  putInvoiceHeaderComplaintFailure,
} = actionCreators;

export interface PurchaseInvoiceReclaimBodyParams {
  receiverEmail: string;
  ccEmail: string | null;
  responseEmail: string | null;
  reason: string | null;
  emailTitle: string;
  emailMessage: string;
}

async function postInvoiceHeaderFileInvoiceComplaint(
  invoiceHeaderId: string,
  reclaimBody: PurchaseInvoiceReclaimBodyParams,
  files: File[]
): Promise<APIUpdatedEntities> {
  const data = new FormData();
  files.forEach((file) => data.append('file', file));

  // only strings or blobs allowed in FormData
  data.set('form_fields', JSON.stringify(reclaimBody));

  const response = await POST<RawAPIUpdatedEntities>(
    `v1/purchase-invoice-headers/${invoiceHeaderId}/reclaim`,
    data
  );

  return mapRawUpdatedEntities(response);
}

export const fileInvoiceComplaintHeader = (
  invoiceHeaderId: string,
  reclaimBody: PurchaseInvoiceReclaimBodyParams,
  files: File[],
  callback?: () => void
) =>
  createAsyncThunk(postInvoiceHeaderFileInvoiceComplaint, {
    args: [invoiceHeaderId, reclaimBody, files],
    initialAction: putInvoiceHeaderComplaintStarted({
      purchaseInvoiceHeaderId: invoiceHeaderId,
    }),
    successActionCreator: (response) => {
      if (callback) {
        callback();
      }

      return putInvoiceHeaderComplaintSuccess({
        purchaseInvoiceHeaderId: invoiceHeaderId,
        ...response,
      });
    },
    failureActionCreator: (error) =>
      putInvoiceHeaderComplaintFailure({
        purchaseInvoiceHeaderId: invoiceHeaderId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
