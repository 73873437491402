import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import { ActualCost } from '../../../../../store/reducers/actualCost';
import { InvoiceHeader } from '../../../../../store/reducers/invoiceHeader';

import {
  startInvoiceHeaderCorrection,
  rehandleActualCostRequest,
} from '../../../../../store/actions';

import useTxt from '../../../../../hooks/useTxt';

import {
  ButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../../../components/Buttons';
import TextInput from '../../../../../components/Input/TextInput';
import Modal, { Content, Header } from '../../../../../components/Modal/Modal';

import { isInvoiceHeader } from '../../../../../utils/general';

export const FormContainer = styled.form`
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
  width: 30vw;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled(Header)`
  padding: ${(props) => `${props.theme.margin[16]} ${props.theme.margin[16]}`};
  height: 48px;
`;

const StyledContent = styled(Content)`
  border-radius: 0 0 ${({ theme }) => theme.radius.medium}
    ${({ theme }) => theme.radius.medium};
  padding: ${(props) => `${props.theme.margin[32]}`};
  max-height: 16rem;
  min-height: 16rem;
`;

const CenteredButtonGroup = styled(ButtonGroup)`
  justify-content: center;
`;

const Description = styled.span`
  padding-bottom: ${(props) => `${props.theme.margin[32]}`};
`;

type CorrectionModalProps = {
  cost: InvoiceHeader | ActualCost;
  onClose: () => void;
};

const CorrectionModal = ({
  cost,
  onClose,
}: React.PropsWithChildren<CorrectionModalProps>) => {
  const [reason, setReason] = useState('');
  const dispatch = useDispatch();

  const confirmationText = useTxt(
    isInvoiceHeader(cost)
      ? 'order.receiveMode.invoiceCorrection.confirmation'
      : 'order.receiveMode.actualCostRehandle.confirmation'
  );

  const informationText = useTxt(
    isInvoiceHeader(cost)
      ? 'order.receiveMode.invoiceCorrection.information'
      : 'order.receiveMode.actualCostRehandle.information'
  );

  const reasonText = useTxt(
    isInvoiceHeader(cost)
      ? 'order.receiveMode.invoiceCorrection.reason'
      : 'order.receiveMode.actualCostRehandle.reason'
  );

  const cancelText = useTxt('common.cancel');
  const saveText = useTxt('common.save');

  const onReasonChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isInvoiceHeader(cost)) {
      dispatch(startInvoiceHeaderCorrection(cost.id, reason ?? ''));
    } else {
      dispatch(rehandleActualCostRequest(cost.id, { comment: reason ?? '' }));
    }

    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <FormContainer onSubmit={onSubmit}>
        <StyledHeader>
          <h2>{confirmationText}</h2>
        </StyledHeader>
        <StyledContent>
          <Description>{informationText}</Description>
          <TextInput label={reasonText} onChange={onReasonChanged} />
          <CenteredButtonGroup>
            <SecondaryButton type="button" onClick={onClose}>
              {cancelText}
            </SecondaryButton>
            <PrimaryButton type="submit">{saveText}</PrimaryButton>
          </CenteredButtonGroup>
        </StyledContent>
      </FormContainer>
    </Modal>
  );
};

export default CorrectionModal;
