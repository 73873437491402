import React from 'react';

import { flexRender, Row } from '@tanstack/react-table';
import { VirtualItem } from '@tanstack/react-virtual';
import styled, { css } from 'styled-components';

import { TargetRowOrTargetRowHierarchyEntry } from '../../hooks/useTargetViewData';

import { GridCellTd } from '../../../../components/Cell';

import { isClickOrKeyboardSelection } from '../../../../utils/mouseOrKeyInteraction';

import styleTheme from '../../../../styles/theme';

type TargetRowOrTargetRowHierarchyEntryProps = {
  virtualRow: VirtualItem;
  row: Row<TargetRowOrTargetRowHierarchyEntry>;
  measureElement: (node: Element | null) => void;
  containerWidth: number;
};

const TargetViewRow = ({
  virtualRow,
  row,
  measureElement,
  containerWidth,
}: TargetRowOrTargetRowHierarchyEntryProps) => {
  const rowWidth = row.getVisibleCells().reduce((acc, cell) => {
    return acc + cell.column.getSize();
  }, 0);

  const toggleRow = () => {
    if (row.getCanExpand()) {
      row.toggleExpanded();
    }
  };

  const onClickOrKeyPressRow = (
    e:
      | React.KeyboardEvent<HTMLTableRowElement>
      | React.MouseEvent<HTMLTableRowElement>
  ) => {
    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      toggleRow();
    }
  };

  return (
    <StyledTr
      key={row.id}
      virtualRow={virtualRow}
      data-index={virtualRow.index}
      ref={(node) => measureElement(node)}
      parent={row.depth === 0 && row.subRows.length > 0}
      canExpand={row.getCanExpand()}
      depth={row.depth}
      onClick={onClickOrKeyPressRow}
      onKeyPress={onClickOrKeyPressRow}
      tabIndex={0}
      isHighlighted={row.original.isHighLighted}
      isDisabled={row.original.isDisabled}
      noHierarchyEntryParent={
        row.original.id === 'hierarchyEntry-none' ||
        row.original.targetRowHierarchyEntryId === 'hierarchyEntry-none'
      }
    >
      {row.getVisibleCells().map((cell) => {
        return (
          <StyledTd
            key={cell.id}
            width={Math.round(
              (cell.column.getSize() / rowWidth) * containerWidth
            )}
            align={cell.column.columnDef?.meta?.align}
            borderWidthRight={cell.column.columnDef?.meta?.borderWidthRight}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </StyledTd>
        );
      })}
    </StyledTr>
  );
};

type TrProps = {
  virtualRow: VirtualItem;
  parent: boolean;
  noHierarchyEntryParent?: boolean;
  isHighlighted?: boolean;
  isDisabled?: boolean;
  depth: number;
  canExpand: boolean;
};

const backgroundColor = (props: TrProps) => {
  if (props.isDisabled) {
    return css`
      background-color: ${styleTheme.color.graphiteB76};
    `;
  }

  if (props.isHighlighted) {
    return css`
      background-color: ${styleTheme.color['M3/ref/primary/primary95']};
    `;
  }
};

const StyledTr = styled.tr.attrs<TrProps>(({ virtualRow }) => ({
  style: { transform: `translateY(${virtualRow.start}px)` },
}))<TrProps>`
  position: absolute;

  border-bottom: ${({ parent }) => (parent ? 2 : 1)}px solid
    ${(props) => props.theme.color.rowBorder};

  width: 100%;
  height: ${({ parent }) => (parent ? 48 : 32)}px;

  display: flex;

  ${(props) => backgroundColor(props)}

  font-size: ${({ depth, theme }) =>
    depth === 0 ? theme.fontSize.h2 : theme.fontSize.base};
  ${({ canExpand }) =>
    canExpand
      ? css`
          cursor: pointer;
        `
      : ''}
  ${({ noHierarchyEntryParent }) =>
    noHierarchyEntryParent
      ? css`
          margin-top: ${(props) => props.theme.margin[40]};
        `
      : ''}
    ${({ noHierarchyEntryParent, parent }) =>
    noHierarchyEntryParent && parent
      ? css`
          border-top: 2px solid ${(props) => props.theme.color.rowBorder};
        `
      : ''}
`;

type TdProps = {
  width: number;
  borderWidthRight?: number;
};

const StyledTd = styled(GridCellTd)<TdProps>`
  border-right: ${({ borderWidthRight }) => borderWidthRight ?? 0}px solid
    ${(props) => props.theme.color.rowBorder};
  width: ${({ width }) => width}px;
  flex-flow: nowrap;
`;

export default TargetViewRow;
