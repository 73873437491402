import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Big from 'big.js';
import styled from 'styled-components';

import { getProjectById } from '../../../store/reducers/project';
import { getWorkPackageCurrentPeriodActualPoC } from '../../../store/reducers/schedule/workPackageTimeline';
import { getActiveProjectId, getColumns } from '../../../store/reducers/ui';

import { getWorkPackageTimelineForProject } from '../../../store/actions/schedule/workPackageTimeline';

import { APIWorkPackage } from '../../../types/api';

import useRemoteData from '../../../hooks/useRemoteData';
import useTxt from '../../../hooks/useTxt';

import {
  BigPriceValue,
  DeltaBigCostValue,
  BigPercentageValue,
  DeltaBigPercentageValue,
  DeltaBigProfitValue,
} from '../../../components/BigValue';
import { IconButton } from '../../../components/Buttons';
import Cell, { LeftPaddedCell } from '../../../components/Cell';
import { SecondaryRow } from '../../../components/Table';
import Tooltip from '../../../components/Tooltip';

import { isPresent } from '../../../utils/general';

import { IconInvalid } from '../../../assets/svg';

import { TextId } from '../../../localization';

import { generateUrl, routes } from '../../../routes';

import { PREDICTION_CHANGE_DISPLAY_TRESHOLD } from './WorkPackageGroup';
import { ColumnType } from '../../../store/localStorage';

type WorkPackageProps = {
  workPackage: APIWorkPackage;
  onClick: (id: string) => void;
};

export const WorkPackageRow = ({ workPackage, onClick }: WorkPackageProps) => {
  const { workSectionViewColumns } = useSelector(getColumns);

  const actualPoC = useRemoteData(
    getWorkPackageCurrentPeriodActualPoC({
      workPackageId: workPackage.id,
      projectId: workPackage.projectId,
    }),
    getWorkPackageTimelineForProject({ projectId: workPackage.projectId })
  );

  const project = useSelector(getProjectById(workPackage.projectId));

  const percentageOfCompletion = actualPoC
    ? actualPoC.percentageOfCompletion
    : new Big(0);

  const {
    id,
    code,
    name,
    targetTotal,
    predictionTotal,
    predictionChangeFromLatest,
    contractTotal,
    changeOrdersTotal,
    reservesTotal,
    receivedTotal,
    additionalTargetTotal,
  } = workPackage;

  const isTasksOrDatesInUse =
    !isPresent(project?.nextgenDefaultScheduleViewId) ||
    workPackage.scheduleTaskIds.length > 0 ||
    (isPresent(workPackage.startDate) && isPresent(workPackage.endDate));

  const zeroPercentageAndReceivedTotal =
    percentageOfCompletion.eq(new Big(0)) && predictionTotal.eq(new Big(0));

  const totals: Partial<Record<TextId, Big | null | string>> = {
    'worksection.table.header.code': code,
    'worksection.table.header.name': name,
    'worksection.table.header.percentageOfCompletion': percentageOfCompletion,
    'worksection.table.header.targetTotal': targetTotal,
    'worksection.table.header.additionalTargetTotal': additionalTargetTotal,
    'worksection.table.header.predictionTotal': predictionTotal,
    'worksection.table.header.receivedTotal': receivedTotal,
    'worksection.table.header.predictionChangeFromLatest':
      predictionChangeFromLatest,
    'worksection.table.header.contractTotal': contractTotal,
    'worksection.table.header.changeOrdersTotal': changeOrdersTotal,
    'worksection.table.header.reservesTotal': reservesTotal,
    'worksection.table.header.remaining': predictionTotal.minus(receivedTotal),
    'worksection.table.header.differenceToTarget':
      targetTotal.minus(predictionTotal),
  };

  return (
    <>
      <StyledSecondaryRow clickable onClick={() => onClick(id)} key={code}>
        {workSectionViewColumns
          .filter((item) => item.selected)
          .map((header) => {
            return (
              <CellContent
                totals={totals}
                invalid={
                  !isTasksOrDatesInUse && !zeroPercentageAndReceivedTotal
                }
                column={header}
                workPackageId={workPackage.id}
                key={`${header.name}-workPackageRow-${workPackage.id}`}
              />
            );
          })}
      </StyledSecondaryRow>
    </>
  );
};

type CellContentProps = {
  column: ColumnType;
  invalid: boolean;
  workPackageId: string;
  totals: Partial<Record<TextId, Big | null | string>>;
};

const CellContent = ({
  column,
  invalid,
  workPackageId,
  totals,
}: CellContentProps) => {
  const tip = useTxt('worksection.table.header.percentageOfCompletion.toolTip');

  const projectId = useSelector(getActiveProjectId) ?? '';

  const history = useHistory();

  const toEditMode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    const url = generateUrl({
      route: routes.WORKSECTION_EXPANDED,
      projectId,
      viewMode: 'edit',
      workPackageId,
    });

    history.push(url);
  };

  switch (column.name) {
    case 'worksection.table.header.code':
      return <LeftPaddedCell>{totals[column.name]?.toString()}</LeftPaddedCell>;
    case 'worksection.table.header.none':
      return null;
    case 'worksection.table.header.percentageOfCompletion':
      return (
        <Cell align={column.align}>
          {invalid ? (
            <Tooltip
              tip={tip}
              className="line-break-compressed-tooltip"
              place="top"
            >
              <StyledIconButton
                onClick={toEditMode}
                icon={IconInvalid}
                data-testid="poc-data-missing-worksection-button"
              />
            </Tooltip>
          ) : null}
          <CellContentValue
            styling={column.numberStyling}
            price={totals[column.name]}
          />
        </Cell>
      );
    default:
      return (
        <Cell align={column.align}>
          <CellContentValue
            styling={column.numberStyling}
            price={totals[column.name]}
          />
        </Cell>
      );
  }
};

type CellContentValueProps = {
  price: Big | null | undefined | string;
  styling:
    | 'price'
    | 'priceDelta'
    | 'empty'
    | 'percentage'
    | 'percentageDelta'
    | 'profitDelta'
    | undefined;
};

export const CellContentValue = ({ price, styling }: CellContentValueProps) => {
  if (!price) {
    return null;
  }

  if (typeof price === 'string') {
    return <>{price}</>;
  }

  switch (styling) {
    case 'percentage':
      return <BigPercentageValue value={price} decimals={0} />;
    case 'percentageDelta':
      return <DeltaBigPercentageValue value={price} decimals={0} />;
    case 'price':
      return <BigPriceValue value={price} decimals={0} />;
    case 'priceDelta':
      return price.abs().gte(PREDICTION_CHANGE_DISPLAY_TRESHOLD) ? (
        <DeltaBigCostValue value={price} decimals={0} />
      ) : null;
    case 'profitDelta':
      return price.abs().gte(PREDICTION_CHANGE_DISPLAY_TRESHOLD) ? (
        <DeltaBigProfitValue value={price} decimals={0} />
      ) : null;
    default:
      return null;
  }
};

const StyledSecondaryRow = styled(SecondaryRow)`
  &:hover {
    box-shadow: inset 0.1rem 0 ${(props) => props.theme.color.darkpurple};
  }
`;

const StyledIconButton = styled(IconButton)`
  margin-right: ${(props) => props.theme.margin[8]};
`;

export default WorkPackageRow;
