import axios, { Method } from 'axios';
import { getAuth } from 'firebase/auth';
import * as t from 'io-ts';
import { DecodeError } from 'io-ts-promise';
import reporter from 'io-ts-reporters';

import { setCsrfToken, getCsrfToken } from './csrfToken';

export enum ApiErrorStatus {
  ValidationError = 422,
  Conflict = 409,
  Unauthorized = 401,
  UnknownError = 520,
}

export type BackendError = {
  status: ApiErrorStatus;
  message: string;
};

export class ApiError extends Error {
  private error: BackendError;

  public getBackendError() {
    return this.error;
  }

  constructor(error: BackendError) {
    super('ApiError');
    this.error = error;
    this.name = 'ApiError';
  }
}

export const apiErrorHandlingWithDecode = (error: unknown) => {
  if (error instanceof ApiError) {
    return error.getBackendError();
  }

  if (error instanceof DecodeError) {
    const report = reporter.report(t.failures(error.errors))[0];

    return {
      status: 422,
      message: report,
    };
  }

  return new ApiError({
    status: 520,
    message: JSON.stringify(error),
  }).getBackendError();
};

const getAutheticationHeader = async () => {
  const auth = getAuth();
  await auth.authStateReady();

  const user = auth.currentUser;

  if (user === null) {
    return undefined;
  }

  return `Bearer ${await user.getIdToken()}`;
};

type RequestProps = {
  method: Method;
  url: string;
  body?: Record<string, unknown> | FormData;
};

const request = async <T>(props: RequestProps) => {
  const baseUrl = getBaseApiUrl();

  if (!baseUrl) {
    return Promise.reject(new Error('Base URL is not set'));
  }

  let authHeader = '';

  if (process.env.REACT_APP_AUTH_PROVIDER === 'firebase') {
    const firebaseAuthHeader = await getAutheticationHeader();

    if (!firebaseAuthHeader) {
      return Promise.reject(new Error('couldnt fetch idToken'));
    }

    authHeader = firebaseAuthHeader;
  }

  return axios
    .request<T>({
      method: props.method,
      url: `${getBaseApiUrl()}${props.url}`,
      headers: {
        'Content-Type':
          props.body && props.body instanceof FormData
            ? 'multipart/form-data'
            : 'application/json',
        ...(process.env.REACT_APP_AUTH_PROVIDER === 'firebase'
          ? { Authorization: authHeader }
          : {}),
        ...(props.method !== 'GET' ? { 'X-CSRF-Token': getCsrfToken() } : {}),
      },
      withCredentials: true,
      data: props.body,
    })
    .then((response) => {
      if (response.headers['x-set-csrf-token']) {
        setCsrfToken(response.headers['x-set-csrf-token']);
      }

      return response.data;
    })
    .catch((error) => {
      // Backend responded with non-200 status code that has valid content
      if (error.response) {
        throw new ApiError(error.response.data);
      }

      if (axios.isCancel(error)) {
        throw new ApiError({ message: 'Cancel Error', status: 520 });
      }

      if (error?.name === 'AbortError') {
        throw new ApiError({ message: 'Network Error', status: 520 });
      }

      // eslint-disable-next-line no-console
      if (error.message === 'Network Error') {
        throw new ApiError({ message: 'Network Error', status: 520 });
      }

      // No response received
      throw new Error('Unknown error when doing request');
    });
};

export const GET = <T = unknown>(url: string) =>
  request<T>({ method: 'GET', url });

export const PUT = <T>(url: string, body: Record<string, unknown>) =>
  request<T>({ method: 'PUT', url, body });

export const POST = <T>(
  url: string,
  body: Record<string, unknown> | FormData
) => request<T>({ method: 'POST', url, body });

export const DELETE = <T>(url: string) => request<T>({ method: 'DELETE', url });

export const getBaseApiUrl = () => {
  const localStorageBaseUrl = localStorage.getItem('baseUrl');
  const envUrl = process.env.REACT_APP_API_BASE_URL;

  if (!envUrl && !localStorageBaseUrl) {
    return undefined;
  }

  return `${localStorageBaseUrl || envUrl}`;
};

export const downloadFile = async (url: string, params?: any) => {
  const baseUrl = getBaseApiUrl();

  if (!baseUrl) {
    return Promise.reject(new Error('Base URL is not set'));
  }

  let authHeader = '';

  if (process.env.REACT_APP_AUTH_PROVIDER === 'firebase') {
    const firebaseAuthHeader = await getAutheticationHeader();

    if (!firebaseAuthHeader) {
      return Promise.reject(new Error('couldnt fetch idToken'));
    }

    authHeader = firebaseAuthHeader;
  }

  return axios
    .request({
      url: `${baseUrl}${url}`,
      method: 'GET',
      params,
      responseType: 'blob', // important
      withCredentials: true,
      headers: {
        ...(process.env.REACT_APP_AUTH_PROVIDER === 'firebase'
          ? { Authorization: authHeader }
          : {}),
      },
    })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      let fileName = 'unknown';
      const searchKey = 'Content-Disposition';
      const asLowercase = searchKey.toLowerCase();

      const objectKey =
        Object.keys(response.headers).find(
          (key) => key.toLowerCase() === asLowercase
        ) ?? 'content-disposition';

      const contentDisposition = response.headers[objectKey] as string;

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);

        if (fileNameMatch?.length === 2) {
          [, fileName] = fileNameMatch;
        }
      }

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName); // or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch((error) => {
      // Backend responded with non-200 status code that has valid content
      if (error.response) {
        throw new ApiError(error.response.data);
      }

      // No response received
      throw new Error('Unknown error when doing request');
    });
};

export const downloadContentForIframe = async (url: string, params?: any) => {
  const baseUrl = getBaseApiUrl();

  if (!baseUrl) {
    return Promise.reject(new Error('Base URL is not set'));
  }

  let authHeader = '';

  if (process.env.REACT_APP_AUTH_PROVIDER === 'firebase') {
    const firebaseAuthHeader = await getAutheticationHeader();

    if (!firebaseAuthHeader) {
      return Promise.reject(new Error('couldnt fetch idToken'));
    }

    authHeader = firebaseAuthHeader;
  }

  return axios
    .request({
      url: `${baseUrl}${url}`,
      method: 'GET',
      params,
      responseType: 'blob', // important
      withCredentials: true,
      headers: {
        ...(process.env.REACT_APP_AUTH_PROVIDER === 'firebase'
          ? { Authorization: authHeader }
          : {}),
      },
    })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      let fileName = 'unknown';
      const searchKey = 'Content-Disposition';
      const asLowercase = searchKey.toLowerCase();

      const objectKey =
        Object.keys(response.headers).find(
          (key) => key.toLowerCase() === asLowercase
        ) ?? 'content-disposition';

      const contentDisposition = response.headers[objectKey] as
        | string
        | undefined;

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);

        if (fileNameMatch?.length === 2) {
          [, fileName] = fileNameMatch;
        }
      }

      const contentTypeSearchKey = 'Content-Type';
      const contentTypeAsLowercase = contentTypeSearchKey.toLowerCase();

      const contentTypeObjectKey =
        Object.keys(response.headers).find(
          (key) => key.toLowerCase() === contentTypeAsLowercase
        ) ?? 'content-type';

      const contentType = response.headers[contentTypeObjectKey] as
        | string
        | undefined;

      return { url: href, fileName, contentType };
    })
    .catch((error) => {
      // Backend responded with non-200 status code that has valid content
      if (error.response) {
        throw new ApiError(error.response.data);
      }

      // No response received
      throw new Error('Unknown error when doing request');
    });
};

export type FileResponse = {
  url: string;
  fileName: string;
  contentType?: string;
};

export const getFileWithCredentials = async (
  url: string
): Promise<FileResponse> => {
  const apiBaseUrl = getBaseApiUrl();

  if (apiBaseUrl && url.includes(apiBaseUrl)) {
    const urlWithoutBaseUrl = url.replace(apiBaseUrl, '');

    return downloadContentForIframe(urlWithoutBaseUrl);
  }

  return { url, fileName: 'unknown', contentType: undefined };
};

export const isFileResponse = (data: unknown): data is FileResponse => {
  return (
    typeof data === 'object' &&
    data !== null &&
    'url' in data &&
    'fileName' in data &&
    typeof (data as FileResponse).url === 'string' &&
    typeof (data as FileResponse).fileName === 'string'
  );
};
