import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppState } from '../../../store/reducers';
import { getProjectById } from '../../../store/reducers/project';
import { getWorkPackagesByWorkPackageGroupId } from '../../../store/reducers/workPackage';
import { getWorkPackageGroupById } from '../../../store/reducers/workPackageGroup';

import {
  uiStateWorkPackageGroupOpened,
  uiStateWorkPackageGroupClosed,
  setLastActiveWorkPackageGroup,
} from '../../../store/actions/ui';

import { ID } from '../../../types/general';

import { isPresent } from '../../../utils/general';

import { generateUrl, useParams, routes } from '../../../routes';

import WorkPackageGroupRow from './WorkPackageGroupRow';
import WorkPackageRow from './WorkPackageRow';

// This is used to filter out prediction changes which have an
// absolute value that is less than this value.
export const PREDICTION_CHANGE_DISPLAY_TRESHOLD = 1.0;

type Props = {
  workPackageGroupId: string;
};

type StateProps = {
  openWorkPackageGroups: ID[];
  lastActiveWorkPackageGroup?: ID;
};

const WorkPackageGroup = ({
  workPackageGroupId,
  openWorkPackageGroups,
  lastActiveWorkPackageGroup,
}: Props & StateProps) => {
  const { projectId } = useParams(routes.WORKSECTIONS);
  const dispatch = useDispatch();
  const history = useHistory();

  const navigateToDetailsPage = (workPackageId: string) => {
    dispatch(setLastActiveWorkPackageGroup(workPackageGroupId));

    const nextUrl = generateUrl({
      route: routes.WORKSECTION_EXPANDED,
      projectId,
      workPackageId,
    });

    history.push(nextUrl);
  };

  const workPackageGroup = useSelector(
    getWorkPackageGroupById(workPackageGroupId)
  );

  const workPackages = useSelector(
    getWorkPackagesByWorkPackageGroupId(workPackageGroupId)
  );

  const project = useSelector(getProjectById(projectId));

  const isPoCCalculatedForAll = workPackages.every(
    (wp) =>
      wp.scheduleTaskIds.length > 0 ||
      (isPresent(wp.startDate) && isPresent(wp.endDate))
  );

  const tasksOrDatesNotInUse =
    isPresent(project?.nextgenDefaultScheduleViewId) && !isPoCCalculatedForAll;

  const [showWorkPackages, setShowWorkPackages] = useState(
    openWorkPackageGroups.includes(workPackageGroupId)
  );

  const toggleShowWorkPackages = () => {
    if (showWorkPackages) {
      dispatch(uiStateWorkPackageGroupClosed(workPackageGroupId));
    } else {
      dispatch(uiStateWorkPackageGroupOpened(workPackageGroupId));
    }

    setShowWorkPackages((visible) => !visible);
  };

  useEffect(() => {
    setShowWorkPackages(openWorkPackageGroups.includes(workPackageGroupId));
  }, [openWorkPackageGroups, workPackageGroupId]);

  if (!workPackageGroup) {
    return null;
  }

  return (
    <tbody>
      <WorkPackageGroupRow
        key={`work-package-group-row-${workPackageGroup.id}`}
        workPackageGroupId={workPackageGroup.id}
        projectId={projectId}
        isOpen={showWorkPackages}
        onClick={toggleShowWorkPackages}
        shouldFocusOnMount={lastActiveWorkPackageGroup === workPackageGroup.id}
        navigateToDetailsPage={navigateToDetailsPage}
        tasksOrDatesNotInUse={tasksOrDatesNotInUse}
      />
      {showWorkPackages
        ? workPackages.map((workPackage) => (
            <WorkPackageRow
              key={`work-package-${workPackage.id}`}
              workPackage={workPackage}
              onClick={navigateToDetailsPage}
            />
          ))
        : null}
    </tbody>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  openWorkPackageGroups: state.ui.openWorkPackageGroups,
  lastActiveWorkPackageGroup: state.ui.lastActiveWorkPackageGroup,
});

export default connect(mapStateToProps)(WorkPackageGroup);
