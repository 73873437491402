import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { flexRender, Header, Table } from '@tanstack/react-table';
import { isEqual } from 'lodash';
import styled from 'styled-components';

import { getTargetRowsForProject } from '../../../../store/reducers/target/targetRows';
import { getTargetViewFilters } from '../../../../store/reducers/ui';

import { requestTargetRowsForProject } from '../../../../store/actions';
import { uiStateTargetViewSearch } from '../../../../store/actions/ui';

import useRemoteData from '../../../../hooks/useRemoteData';
import useTxt from '../../../../hooks/useTxt';
import { TargetRowOrTargetRowHierarchyEntry } from '../../hooks/useTargetViewData';

import { IconButton } from '../../../../components/Buttons';
import { GridCellTh } from '../../../../components/Cell';
import SearchInput from '../../../../components/Input/SearchInput';
import Txt from '../../../../components/Txt';

import { useDebouncedValue } from '../../../../utils/hooks';

import {
  IconUp,
  IconDown,
  IconDoubleDown,
  IconDoubleRight,
} from '../../../../assets/svg';

import { routes, useParams } from '../../../../routes';

type ThProps = {
  header: Header<TargetRowOrTargetRowHierarchyEntry, unknown>;
  width: number;
};

const Th = ({ header, width }: ThProps) => {
  return (
    <StyledTh
      width={width}
      align={header.column.columnDef?.meta?.align}
      borderWidthRight={header.column.columnDef?.meta?.borderWidthRight}
    >
      {flexRender(header.column.columnDef.header, header.getContext())}
    </StyledTh>
  );
};

type ThCellProps = {
  width: number;
  borderWidthRight?: number;
};

const StyledTh = styled(GridCellTh)<ThCellProps>`
  border-right: ${({ borderWidthRight }) => borderWidthRight ?? 0}px solid
    ${(props) => props.theme.color.rowBorder};
  width: ${({ width }) => width}px;
  height: 48px;
  font-size: ${({ theme }) => theme.fontSize.h2};
`;

export default Th;

type InputState = { value: string; targetRowIds?: string[] };

type CustomThProps = {
  table: Table<TargetRowOrTargetRowHierarchyEntry>;
};

export const TargetTableSearchInput = ({ table }: CustomThProps) => {
  const { projectId } = useParams(routes.TARGET);
  const dispatch = useDispatch();

  const history = useHistory();

  const targetRows =
    useRemoteData(
      getTargetRowsForProject({ projectId }),
      requestTargetRowsForProject({ projectId })
    ) ?? [];

  const [hashTargetRowIdState, setHashTargetRowIdState] = React.useState<
    string | null
  >(null);

  const { hash } = history.location;

  const hashParams = hash.replace('#targetRowIds-', '');

  const hashTargetRowIds = hashParams.split(',');

  const hashTargetRows = targetRows.filter((row) =>
    hashTargetRowIds.includes(row.id)
  );

  const { filterSearchWord, targetRowIds } = useSelector(
    getTargetViewFilters()
  );

  const hashDescription = React.useCallback(() => {
    if (hashTargetRows.length === 1 && hashTargetRows[0].description) {
      return hashTargetRows[0].description;
    }

    if (hashTargetRows.length > 1) {
      return ' ';
    }

    return '';
  }, [hashTargetRows]);

  React.useEffect(() => {
    if (hashParams !== hashTargetRowIdState && hashParams !== '') {
      setFilterValue({
        value: hashDescription(),
        targetRowIds: hashTargetRowIds,
      });
      setHashTargetRowIdState(hashParams);
    }
  }, [
    hashParams,
    hashTargetRowIdState,
    hashTargetRowIds,
    hashTargetRows,
    hashDescription,
  ]);

  const [filterValue, setFilterValue] = React.useState<InputState>({
    value: '',
  });

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue({ value: e.target.value });
  };

  const debouncedValue = useDebouncedValue(filterValue, 500);

  const isDebouncedTargetRowIdsSame = React.useCallback(() => {
    if (targetRowIds && debouncedValue.targetRowIds) {
      return isEqual(targetRowIds, debouncedValue.targetRowIds);
    }

    if (!targetRowIds && !debouncedValue.targetRowIds) {
      return true;
    }

    return false;
  }, [debouncedValue.targetRowIds, targetRowIds]);

  React.useEffect(() => {
    if (
      debouncedValue.value === filterSearchWord &&
      isDebouncedTargetRowIdsSame()
    ) {
      return;
    }

    dispatch(
      uiStateTargetViewSearch({
        searchString: debouncedValue.value,
        targetRowIds: debouncedValue.targetRowIds,
      })
    );

    if (
      (debouncedValue.targetRowIds &&
        debouncedValue.targetRowIds?.length > 0) ||
      debouncedValue.value.length > 0
    ) {
      table.toggleAllRowsExpanded(true);
    }
  }, [
    debouncedValue.targetRowIds,
    debouncedValue.value,
    dispatch,
    filterSearchWord,
    isDebouncedTargetRowIdsSame,
    table,
  ]);

  return (
    <SearchDiv>
      <SearchInput
        name="targetViewSearch"
        onChange={onSearchInputChange}
        value={filterValue.value}
        handleClearButtonClick={() =>
          setFilterValue({
            value: '',
          })
        }
        noMargin
      />
    </SearchDiv>
  );
};

const SearchDiv = styled.div`
  margin: ${(props) => props.theme.margin[4]};
`;

const textIdHead = 'target.table.header.';

export const ReferenceNoTh = ({ table }: CustomThProps) => {
  const [openDepth, setOpenDepth] = React.useState(0);
  const allOpenText = useTxt('target.table.hierarchies.open');
  const allClosedText = useTxt('target.table.hierarchies.closed');
  const openLevelText = useTxt('target.table.hierarchies.openLevel');
  const closeLevelText = useTxt('target.table.hierarchies.closeLevel');

  const tableToggleAll = table.getToggleAllRowsExpandedHandler();

  const allExpanded = table.getIsAllRowsExpanded();

  const maxDepth = Math.max(
    ...table.getExpandedRowModel().flatRows.map((row) => row.depth)
  );

  const toggleLevels = (depthChange: number) => {
    const newDepth = openDepth + depthChange;

    table
      .getExpandedRowModel()
      .flatRows.filter((row) => row.depth < newDepth)
      .map((row) => row.toggleExpanded(true));

    table
      .getExpandedRowModel()
      .flatRows.filter((row) => row.depth >= newDepth)
      .map((row) => row.toggleExpanded(false));

    setOpenDepth(newDepth);
  };

  React.useEffect(() => {
    if (table.getIsAllRowsExpanded() && openDepth !== maxDepth) {
      setOpenDepth(maxDepth);
    }
  }, [table, openDepth, maxDepth]);

  const toggleAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    tableToggleAll(e);

    if (allExpanded) {
      setOpenDepth(0);
    } else {
      setOpenDepth(maxDepth);
    }
  };

  return (
    <StyledToggleLevelDiv>
      <StyledIconButton
        icon={table.getIsAllRowsExpanded() ? IconDoubleDown : IconDoubleRight}
        onClick={toggleAll}
        aria-label={table.getIsAllRowsExpanded() ? allOpenText : allClosedText}
      />
      <StyledIconButton
        icon={IconDown}
        onClick={() => toggleLevels(1)}
        disabled={allExpanded}
        aria-label={openLevelText}
      />
      <StyledIconButton
        icon={IconUp}
        onClick={() => toggleLevels(-1)}
        disabled={openDepth === 0}
        aria-label={closeLevelText}
      />
      <Txt id={`${textIdHead}referenceNo` as const} component="b" />
    </StyledToggleLevelDiv>
  );
};

const StyledIconButton = styled(IconButton)`
  margin-right: ${(props) => props.theme.margin[4]};
`;

const StyledToggleLevelDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
