import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled, { css } from 'styled-components';

import { getProjectById } from '../../../store/reducers/project';
import {
  getWorkSectionClasses,
  getWorkPackages,
  getTeams,
  getVirtualNamespaces,
  NextgenWorkPackage,
  getWorkPackageId,
  parseWorkPackageId,
  isLoading,
} from '../../../store/reducers/schedule/nextgenScheduleData';
import { getActiveProjectId } from '../../../store/reducers/ui';
import { getProjectWorkPackages } from '../../../store/reducers/workPackage';

import { fetchWorkPackagesForProject } from '../../../store/actions';
import { fetchNextgenScheduleDataForProject } from '../../../store/actions/schedule/nextgenScheduleData';

import { APIWorkPackage } from '../../../types/api';

import useRemoteData from '../../../hooks/useRemoteData';
import useTxt from '../../../hooks/useTxt';

import {
  IconButton,
  PrimaryButton,
  PrimaryButtonSmall,
} from '../../../components/Buttons';
import { Spinner } from '../../../components/Loading';
import { Table } from '../../../components/Table';
import NoDataInfoBox from '../../../components/Table/NoDataInfoBox';
import Txt from '../../../components/Txt';

import { isNotNull } from '../../../utils/general';

import {
  IconCollapseMinus,
  IconExclamationMark,
  IconExpandPlus,
} from '../../../assets/svg';

import LinkWorkPackagesModal from './LinkWorkPackagesModal';
import {
  StyledInfoSpan,
  StyledPoCContainer,
  StyledPocValue,
} from './NewEditWorkPackageForm';
import NewScheduleTreeRow, { ScheduleCell } from './NewScheduleTreeRow';
import WorkSectionClassDropdown from './WorkSectionClassDropdown';

const tableColumns = {
  none: { width: '1rem' },
  workPackage: { width: '10rem' },
  location: { width: '12rem' },
  workSectionClass: { width: '12rem' },
  team: { width: '12rem' },
  startDate: { width: '6rem' },
  endDate: { width: '6rem' },
  status: { width: '6rem' },
  buttons: { width: '5rem' },
};

export type ExtendedScheduleWorkPackageType = NextgenWorkPackage & {
  parents: string[];
  depth: number;
  workSectionClassId: string | null;
  lowest: boolean;
  teamColor: string | undefined;
  teamName: string | undefined;
  virtualSpaceName: string | undefined;
  workSectionClassName: string | undefined;
};

const getWorkPackageWithChildren = (
  selectedWorkSectionClasses: string[],
  manualLinkedWorkPackages: string[],
  workPackagesWithParents: ExtendedScheduleWorkPackageType[],
  withParents: boolean
) => {
  const filteredWorkPackages = workPackagesWithParents.filter(
    (workPackage) =>
      (workPackage.workSectionClassId &&
        selectedWorkSectionClasses.includes(workPackage.workSectionClassId)) ||
      manualLinkedWorkPackages.includes(workPackage.id)
  );

  // select also children if parent is selected
  const filteredWorkPackagesWithChildren = workPackagesWithParents.filter(
    (wp) => {
      const filteredIds = filteredWorkPackages.map((row) => row.id);

      const parentsAndSelf = [wp.id, ...wp.parents];

      return parentsAndSelf.some((parent) => filteredIds.includes(parent));
    }
  );

  if (!withParents) {
    return filteredWorkPackagesWithChildren;
  }

  const parentsToBeShown = filteredWorkPackagesWithChildren.reduce(
    (acc, workPackage) => {
      return [...acc, ...workPackage.parents];
    },
    [] as string[]
  );

  const uniqueParentsToBeShown = [...new Set(parentsToBeShown)];

  const filteredWorkPackageIds = filteredWorkPackagesWithChildren.map(
    (row) => row.id
  );

  const filteredWorkPackagesWithParents = workPackagesWithParents.filter(
    (workPackage) =>
      uniqueParentsToBeShown.includes(workPackage.id) ||
      filteredWorkPackageIds.includes(workPackage.id)
  );

  return filteredWorkPackagesWithParents;
};

type NewScheduleTreeProps = {
  workPackage: APIWorkPackage;
  setScheduleTreeSelection: React.Dispatch<
    React.SetStateAction<{
      workSectionClassId?: string | null | undefined;
      workPackageVirtualSpaceLinkages?: {
        nextgenWorkPackageId: string | null;
        virtualSpaceId: string | null;
      }[];
    }>
  >;
  scheduleTreeSelection: {
    workSectionClassId?: string | null | undefined;
    workPackageVirtualSpaceLinkages?: {
      nextgenWorkPackageId: string | null;
      virtualSpaceId: string | null;
    }[];
  };
};

const NewScheduleTree = ({
  workPackage,
  setScheduleTreeSelection,
  scheduleTreeSelection,
}: NewScheduleTreeProps) => {
  const projectId = useSelector(getActiveProjectId) ?? '';

  const dispatch = useDispatch();

  const [closedWorkPackages, setClosedWorkPackages] = React.useState<string[]>(
    []
  );

  const project = useSelector(getProjectById(projectId));

  const isDataLoading = useSelector(isLoading(projectId));

  const [pocValue, setPoCValue] = React.useState<number>(0);

  const headerInfo = {
    code: workPackage.code,
    name: workPackage.name,
  };

  const [linkWorkpackagesModalOpen, setLinkWorkPackagesModalOpen] =
    React.useState<boolean>(false);

  const setSelectedWorkSectionClass = (worksection: string) => {
    setScheduleTreeSelection({
      ...scheduleTreeSelection,
      workSectionClassId: worksection === 'NONE' ? null : worksection,
    });
  };
  const selectedWorkSectionClass = scheduleTreeSelection.workSectionClassId;

  const projectWorkPackages =
    useRemoteData(
      getProjectWorkPackages(projectId),
      fetchWorkPackagesForProject(projectId)
    ) ?? [];

  const otherFinanceWorkSections = projectWorkPackages.filter(
    (wp) => wp.id !== workPackage.id
  );

  const manualLinkedWorkPackages =
    scheduleTreeSelection.workPackageVirtualSpaceLinkages?.map((wp) =>
      getWorkPackageId({
        id: wp.nextgenWorkPackageId ?? '',
        virtual_space_id: wp.virtualSpaceId,
      })
    ) ?? [];

  const setManualLinkedWorkPackages = (
    workPackages: {
      nextgenWorkPackageId: string | null;
      virtualSpaceId: string | null;
    }[]
  ) => {
    setScheduleTreeSelection({
      ...scheduleTreeSelection,
      workPackageVirtualSpaceLinkages: workPackages,
    });
  };

  const selectedWorkSectionClassWithoutNone =
    selectedWorkSectionClass === 'NONE' ? null : selectedWorkSectionClass;

  const workPackageHeader = useTxt(
    'worksection.workpackage.scheduleWorkPackage'
  );
  const startDateHeader = useTxt('worksection.workpackage.startDate');
  const endDateHeader = useTxt('worksection.workpackage.endDate');
  const locationHeader = useTxt('worksection.workpackage.location');
  const workSectionClassHeader = useTxt('common.workSectionClass');
  const teamHeader = useTxt('worksection.workpackage.team');
  const statusHeader = useTxt('worksection.workpackage.status');

  const noWorkpackagesAvailableHeader = useTxt(
    'worksection.workpackage.noWorkpackagesAvailable'
  );

  const noWorkpackagesAvailableBody = useTxt(
    'worksection.workpackage.noWorkpackagesAvailable.body'
  );

  const noWorkpackagesAvailableLink = useTxt(
    'worksection.workpackage.noWorkpackagesAvailable.link'
  );

  // force refetch data from backend on every modal open
  React.useEffect(() => {
    dispatch(fetchNextgenScheduleDataForProject({ projectId }));
  }, [projectId, dispatch]);

  const workSectionClasses =
    useRemoteData(
      getWorkSectionClasses(projectId),
      fetchNextgenScheduleDataForProject({ projectId })
    ) ?? [];

  const workPackages =
    useRemoteData(
      getWorkPackages(projectId),
      fetchNextgenScheduleDataForProject({ projectId })
    ) ?? [];

  const virtualSpaces =
    useRemoteData(
      getVirtualNamespaces(projectId),
      fetchNextgenScheduleDataForProject({ projectId })
    ) ?? [];

  const teams =
    useRemoteData(
      getTeams(projectId),
      fetchNextgenScheduleDataForProject({ projectId })
    ) ?? [];

  const workPackagesWithParents = workPackages.map((data) => {
    let depth = 0; // set max depth of 15
    let latestParentId = data.parent_id;
    const parents: string[] = [];

    const workSectionClassId = data.work_section_id;

    while (depth < 15 && latestParentId !== null) {
      const parentId = latestParentId;

      const parent = workPackages.find((item) => item.id === parentId);

      if (parent?.id) {
        parents.push(parent?.id);
      }

      latestParentId = parent?.parent_id ?? null;

      depth += 1;
    }

    const allWorkParentIds = workPackages.map((item) => item.parent_id);

    const teamColor = teams.find((team) => team.id === data.team_ids[0])?.color;
    const teamName = teams.find((team) => team.id === data.team_ids[0])?.name;

    const virtualSpaceName = virtualSpaces.find(
      (vs) => vs.id === data.virtual_space_id
    )?.name;

    const workSectionClassName = workSectionClasses.find(
      (row) => row.id === data.work_section_id
    )?.name;

    return {
      ...data,
      parents,
      depth,
      workSectionClassId: workSectionClassId ?? null,
      lowest: !allWorkParentIds.includes(data.id),
      teamColor,
      teamName,
      virtualSpaceName,
      workSectionClassName,
    };
  });

  const toggleRow = (workPackageId: string) => {
    if (closedWorkPackages.includes(workPackageId)) {
      setClosedWorkPackages(
        closedWorkPackages.filter((id) => id !== workPackageId)
      );
    } else {
      setClosedWorkPackages([...closedWorkPackages, workPackageId]);
    }
  };

  const collapseAll = () => {
    setClosedWorkPackages(workPackages.map((wp) => wp.id));
  };

  const allWorkSectionParents = workSectionClasses
    .map((data) => data.parent_id)
    .filter(isNotNull);

  const mappedWorkSectionClasses = workSectionClasses
    .map((data) => {
      let depth = 0; // set max depth of 15
      let latestParentId = data.parent_id;
      const parents: string[] = [];

      while (depth < 15 && latestParentId !== null) {
        const parentId = latestParentId;

        const parent = workSectionClasses.find((item) => item.id === parentId);

        if (parent?.id) {
          parents.push(parent?.id);
        }

        latestParentId = parent?.parent_id ?? null;

        depth += 1;
      }

      return {
        id: data.id,
        value: data.id,
        label: `${data.code} ${data.name}`,
        parent_id: data.parent_id,
        parents,
        depth,
        lowest: !allWorkSectionParents.includes(data.id),
      };
    })
    .sort((a, b) => {
      const selfAndParentsA = [a.id, ...a.parents, 'common-fake-parent'];
      const selfAndParentsB = [b.id, ...b.parents, 'common-fake-parent'];

      const intersection = selfAndParentsA.filter((element) =>
        selfAndParentsB.includes(element)
      );

      if (b.parents.includes(a.id)) {
        return -1;
      }

      if (a.parents.includes(b.id)) {
        return 1;
      }

      const lastCommonParent = intersection[0];

      const indexA = selfAndParentsA.indexOf(lastCommonParent) - 1;
      const indexB = selfAndParentsB.indexOf(lastCommonParent) - 1;

      const siblingA = selfAndParentsA[indexA];
      const siblingB = selfAndParentsB[indexB];

      const workSectionCodeA =
        workSectionClasses.find((data) => data.id === siblingA)?.code ?? '';

      const workSectionCodeB =
        workSectionClasses.find((data) => data.id === siblingB)?.code ?? '';

      if (workSectionCodeA < workSectionCodeB) {
        return -1;
      }

      if (workSectionCodeA > workSectionCodeB) {
        return 1;
      }

      return 0;
    });

  const selectedWorkSectionClasses = selectedWorkSectionClassWithoutNone
    ? mappedWorkSectionClasses
        .filter(
          (data) =>
            data.parents.includes(selectedWorkSectionClassWithoutNone) ||
            data.id === selectedWorkSectionClassWithoutNone
        )
        .map((data) => data.id)
    : [];

  const mappedOtherFinanceWorkSections = otherFinanceWorkSections.map(
    (finaWp) => {
      const workSectionClass = finaWp.workSectionClassId;

      const manualLinkages = finaWp.workPackageVirtualSpaceLinkages
        ? finaWp.workPackageVirtualSpaceLinkages?.map((wp) =>
            getWorkPackageId({
              id: wp.nextgenWorkPackageId ?? '',
              virtual_space_id: wp.virtualSpaceId,
            })
          )
        : [];

      const workSectionClassesWithChildren = workSectionClass
        ? mappedWorkSectionClasses
            .filter(
              (data) =>
                data.parents.includes(workSectionClass) ||
                data.id === workSectionClass
            )
            .map((data) => data.id)
        : [];

      const allRelatedWorkPackages = getWorkPackageWithChildren(
        workSectionClassesWithChildren,
        manualLinkages,
        workPackagesWithParents,
        false
      ).map((wp) => wp.id);

      return {
        id: finaWp.id,
        code: finaWp.code,
        name: finaWp.name,
        scheduleWorkPackageIds: allRelatedWorkPackages,
      };
    }
  );

  const filteredWorkPackagesWithChildren = getWorkPackageWithChildren(
    selectedWorkSectionClasses,
    manualLinkedWorkPackages,
    workPackagesWithParents,
    false
  );

  const otherFinanceWorkPackagesRelatedScheduleWorkPackageIds = [
    ...new Set(
      mappedOtherFinanceWorkSections
        .map((wp) => wp.scheduleWorkPackageIds)
        .flat()
    ),
  ];

  const memoizedSelection = React.useMemo(
    () => filteredWorkPackagesWithChildren,
    [filteredWorkPackagesWithChildren]
  );

  React.useEffect(() => {
    if (memoizedSelection.length !== 0) {
      const childInstancesOnly = memoizedSelection.filter((wp) => wp.lowest);

      const totalProgress = childInstancesOnly.reduce(
        (acc, task) => {
          const progress =
            (task.progress_percentage / 100) *
            (task.cur_dur ?? task.s_high - task.s_low);
          const total = task.cur_dur ?? task.s_high - task.s_low;

          return {
            progress: acc.progress + progress,
            total: acc.total + total,
          };
        },
        {
          progress: 0,
          total: 0,
        }
      );

      const poc =
        totalProgress.total !== 0
          ? (totalProgress.progress / totalProgress.total) * 100
          : 0;

      setPoCValue(poc);
    }
  }, [memoizedSelection, setPoCValue]);

  const filteredWorkPackagesWithChildrenWithoutManual =
    getWorkPackageWithChildren(
      selectedWorkSectionClasses,
      [],
      workPackagesWithParents,
      false
    );

  const filteredWorkPackagesWithParents = getWorkPackageWithChildren(
    selectedWorkSectionClasses,
    manualLinkedWorkPackages,
    workPackagesWithParents,
    true
  );

  const filteredTopLevelWorkPackages = filteredWorkPackagesWithParents.filter(
    (wp) => wp.parent_id === null
  );

  const overlappingLinkagesExist = filteredWorkPackagesWithChildren
    .map((e) => e.id)
    .some((e) =>
      otherFinanceWorkPackagesRelatedScheduleWorkPackageIds.includes(e)
    );

  const deSelectWorkPackage = (id: string) => {
    const childrenIds = workPackagesWithParents
      .filter((wp) => wp.parents.includes(id))
      .map((wp) => wp.id);

    const parentIds = workPackagesWithParents
      .filter((wp) => wp.id === id)
      .map((wp) => wp.parents)
      .flat();

    const uniqueParentIds = [...new Set(parentIds)];

    const allIds = [...childrenIds, id, ...uniqueParentIds];

    const newSelection = manualLinkedWorkPackages
      .filter((wp) => {
        return !allIds.includes(wp);
      })
      .map(parseWorkPackageId)
      .map((wp) => ({
        nextgenWorkPackageId: wp.id,
        virtualSpaceId: wp.virtual_space_id,
      }));

    setManualLinkedWorkPackages(newSelection);
  };

  const setUnParsedManualLinkedWorkPackages = (ids: string[]) => {
    const parsedIds = ids.map(parseWorkPackageId).map((wp) => ({
      nextgenWorkPackageId: wp.id,
      virtualSpaceId: wp.virtual_space_id,
    }));

    setManualLinkedWorkPackages(parsedIds);
  };

  function scheduleLinkClicked() {
    const url = project?.externalUrl;

    if (url) {
      window.open(url, '_blank');
    }
  }

  const renderTable = () => {
    if (isDataLoading) {
      return (
        <tr>
          <ScheduleCell colSpan={10}>
            <SpinnerDiv>
              <Spinner size="4rem" />
            </SpinnerDiv>
          </ScheduleCell>
        </tr>
      );
    }

    if (
      filteredWorkPackagesWithChildren.length === 0 &&
      selectedWorkSectionClassWithoutNone !== null
    ) {
      return (
        <tr>
          <ScheduleCell colSpan={10}>
            <NoDataInfoBox
              headerText={noWorkpackagesAvailableHeader}
              bodyText={noWorkpackagesAvailableBody}
            >
              <PrimaryButtonSmall onClick={scheduleLinkClicked}>
                {noWorkpackagesAvailableLink}
              </PrimaryButtonSmall>
            </NoDataInfoBox>
          </ScheduleCell>
        </tr>
      );
    }

    return filteredTopLevelWorkPackages.length === 0 ? (
      <tr>
        <ScheduleCell colSpan={10}>
          <Txt id="worksection.workpackage.noLinkages" />
        </ScheduleCell>
      </tr>
    ) : (
      filteredTopLevelWorkPackages.map((wp) => {
        return (
          <NewScheduleTreeRow
            key={wp.id}
            scheduleWorkPackage={wp}
            depth={0}
            allWorkPackages={filteredWorkPackagesWithParents}
            allSelectedWorkPackages={filteredWorkPackagesWithChildren.map(
              (e) => e.id
            )}
            otherExistingLinkages={mappedOtherFinanceWorkSections}
            closedWorkPackageIds={closedWorkPackages}
            toggleRow={toggleRow}
            deSelectDisabledIds={filteredWorkPackagesWithChildrenWithoutManual.map(
              (e) => e.id
            )}
            deSelectWorkPackage={deSelectWorkPackage}
          />
        );
      })
    );
  };

  return (
    <>
      <StyledSection>
        <StyledInfoSpan>
          <Txt id="worksection.workpackage.linkedSchedule.explanation" />
        </StyledInfoSpan>
      </StyledSection>
      <StyledSection>
        <WorkSectionClassDropdown
          onSelectionChange={setSelectedWorkSectionClass}
          workSectionClassOptions={mappedWorkSectionClasses}
          initialValue={selectedWorkSectionClass}
        />
        <StyledPrimaryButton onClick={() => setLinkWorkPackagesModalOpen(true)}>
          <StyledButtonSpan>
            <Txt id="worksection.workpackage.linkToWorkpackages" />
          </StyledButtonSpan>
        </StyledPrimaryButton>
      </StyledSection>
      <ConnectedWorkPackagesContainer>
        <Table>
          <colgroup>
            <Col name="none" />
            <Col name="workPackage" />
            <Col />
            <Col name="location" />
            <Col name="workSectionClass" />
            <Col name="team" />
            <Col name="startDate" />
            <Col name="endDate" />
            <Col name="status" />
            <Col name="buttons" />
          </colgroup>
          <TableHeader>
            <tr>
              <StyledTh colSpan={3}>
                <FlexRowDiv>
                  <HeaderTextSpan>{workPackageHeader}</HeaderTextSpan>
                  <ButtonDiv>
                    <ExpandCollapseButton
                      icon={IconExpandPlus}
                      customSize="12px"
                      data-testid="expand-all-button"
                      onClick={() => setClosedWorkPackages([])}
                    />
                    <ExpandCollapseButton
                      icon={IconCollapseMinus}
                      customSize="12px"
                      data-testid="collapse-all-button"
                      onClick={() => collapseAll()}
                    />
                  </ButtonDiv>
                </FlexRowDiv>
              </StyledTh>
              <StyledTh>{locationHeader}</StyledTh>
              <StyledTh>{workSectionClassHeader}</StyledTh>
              <StyledTh>{teamHeader}</StyledTh>
              <StyledTh>{startDateHeader}</StyledTh>
              <StyledTh>{endDateHeader}</StyledTh>
              <StyledTh>{statusHeader}</StyledTh>
              <StyledTh />
            </tr>
          </TableHeader>
          <StyledTbody>{renderTable()}</StyledTbody>
        </Table>
      </ConnectedWorkPackagesContainer>
      <StyledPoCContainer>
        <Txt id="worksection.workpackage.pocToday" />
        <StyledPocValue>{`${pocValue.toFixed(0)}%`}</StyledPocValue>
        {overlappingLinkagesExist ? (
          <>
            <ExistingIcon />
            <Txt id="worksection.workpackage.overlappingLinkages" />
          </>
        ) : null}
      </StyledPoCContainer>
      <EmptyDiv />
      {linkWorkpackagesModalOpen ? (
        <LinkWorkPackagesModal
          onClose={() => setLinkWorkPackagesModalOpen(false)}
          scheduleWorkPackages={workPackagesWithParents}
          headerInfo={headerInfo}
          manualLinkedWorkPackages={manualLinkedWorkPackages}
          selectedBasedOnWorkSection={filteredWorkPackagesWithChildrenWithoutManual.map(
            (wp) => wp.id
          )}
          setLinkedWorkPackages={setUnParsedManualLinkedWorkPackages}
        />
      ) : null}
    </>
  );
};

export default NewScheduleTree;

const StyledSection = styled.div`
  margin-top: ${(props) => props.theme.margin[16]};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonSpan = styled.span`
  width: 100%;

  display: inline-block;

  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${(props) => props.theme.fontSize.small};

  overflow: hidden;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 16rem;

  align-self: flex-end;

  background-color: ${(props) =>
    props.theme.color['M3/sys/light/secondary-container']};

  color: ${(props) => props.theme.color['M3/sys/light/on-secondary-container']};
  font-size: ${(props) => props.theme.fontSize.textButton};
  font-weight: 700;
`;

const ConnectedWorkPackagesContainer = styled.div`
  position: relative;

  margin-top: ${(props) => props.theme.margin[16]};

  height: 25rem;

  font-size: 0.8rem;

  overflow-y: auto;
`;

const TableHeader = styled.thead`
  position: sticky;
  top: 0;

  margin-bottom: ${(props) => props.theme.margin[64]};

  box-shadow:
    -1px 0 white,
    1px 0px white,
    0px 1px 3px grey;

  background-color: white;

  overflow-y: auto;

  z-index: 1;
`;

const StyledTh = styled.th`
  border-right: ${(props) =>
    `1px solid ${props.theme.color['M3/ref/neutral/neutral90']}`};
  box-shadow: inset 1px 0 white;

  padding: ${(props) => props.theme.margin[8]};

  font-size: 0.9rem;
  font-weight: 600;
  text-align: left;
`;

type ColProps = {
  name?: keyof typeof tableColumns;
};

const Col = styled.col<ColProps>`
  ${({ name }) =>
    name
      ? css`
          width: ${tableColumns[name].width};
        `
      : ''}
`;

const StyledTbody = styled.tbody`
  border: ${(props) =>
    `1px solid ${props.theme.color['M3/ref/neutral/neutral90']}`};
`;

const EmptyDiv = styled.div`
  height: 4.5rem;
`;

const HeaderTextSpan = styled.span`
  flex: 1 1 0%;
`;

const ButtonDiv = styled.div`
  display: flex;
  flex: 0 0 ${(props) => props.theme.margin[32]};
`;

const FlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ExpandCollapseButton = styled(IconButton)`
  /** give user some room for error click*/
  padding: 4px;
  width: 20px;
  height: 20px;
`;

const SpinnerDiv = styled.div`
  margin: ${(props) => props.theme.margin[16]};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExistingIcon = styled(IconExclamationMark)`
  margin: 0 ${(props) => props.theme.margin[8]} 0;
  width: ${(props) => props.theme.sizes.iconButtonSmall};
  height: ${(props) => props.theme.sizes.iconButtonSmall}
    ${(props) => props.theme.margin[16]};
  fill: ${(props) => props.theme.color.orange};
`;
