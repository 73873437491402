import { Reducer } from 'redux';

import { BackendError } from '../../../utils/api';
import { flow } from '../../../utils/function';
import { isDefined, isNotNull } from '../../../utils/general';
import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { AppState } from '..';
import { ProjectTimelineEntry } from '../../actions/schedule/projectTimeline';
import { ActionTypes as Action } from '../../actionTypes';

type Err = BackendError | undefined;

type State = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  data: Record<string, ProjectTimelineEntry[]>;
};

const initialState: State = {
  requests: {},
  data: {},
};

const reducer: Reducer<State, Action> = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PROJECT_TIMELINES_STARTED': {
      const { projectId } = action.payload;
      const requests = { ...state.requests, [projectId]: remoteData.loading };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_PROJECT_TIMELINES_FAILURE': {
      const { projectId, error } = action.payload;

      const requests = {
        ...state.requests,
        [projectId]: remoteData.fail(error),
      };

      return { ...state, requests };
    }

    case 'GET_PROJECT_TIMELINES_SUCCESS': {
      const { projectId, projectTimelineEntries } = action.payload;

      const requests = {
        ...state.requests,
        [projectId]: remoteData.succeed(undefined),
      };

      return {
        requests,
        data: { ...state.data, [projectId]: projectTimelineEntries },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

export function getRequestState(
  projectId: string
): Selector<remoteData.RemoteData['kind']> {
  return ({
    schedule: {
      projectTimelines: {
        requests: { [projectId]: requestState = remoteData.notAsked },
      },
    },
  }) => requestState.kind;
}

export function isLoading(projectId: string): Selector<boolean> {
  return flow(
    getRequestState(projectId),
    (requestState) => requestState === 'Loading'
  );
}

export const getProjectTimelineEntriesForProject: (
  projectId: string
) => Selector<remoteData.RemoteData<ProjectTimelineEntry[]>> =
  (projectId) =>
  ({
    schedule: {
      projectTimelines: {
        requests: { [projectId]: requestState = remoteData.notAsked },
        data,
      },
    },
  }) =>
    remoteData.map(requestState, (_) => {
      const timelineData = data[projectId];

      if (!timelineData) {
        return [];
      }

      return timelineData;
    });

export const getProjectCurrentPeriodActualPoC: ({
  projectId,
}: {
  projectId: string;
}) => (
  appState: AppState
) => remoteData.RemoteData<ProjectTimelineEntry | undefined> = ({
  projectId,
}) =>
  flow(getProjectTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredEntry = entries.filter(
        (entry) =>
          entry.projectId === projectId &&
          entry.snapshotTypeId === '1' &&
          entry.snapshotId === null &&
          entry.currentPeriod
      )[0];

      return filteredEntry;
    })
  );

export const getProjectCurrentPeriodLatestSnapshotPlannedPoC: ({
  projectId,
}: {
  projectId: string;
}) => (
  appState: AppState
) => remoteData.RemoteData<ProjectTimelineEntry | undefined> = ({
  projectId,
}) =>
  flow(getProjectTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredEntry = entries.filter(
        (entry) =>
          entry.projectId === projectId &&
          entry.snapshotTypeId === '2' &&
          entry.latestSnapshot &&
          entry.currentPeriod &&
          entry.pastPeriod === false
      )[0];

      return filteredEntry;
    })
  );

export const getProjectCurrentPeriodBasedOnPreviouslyPlannedPoC: ({
  projectId,
}: {
  projectId: string;
}) => (
  appState: AppState
) => remoteData.RemoteData<ProjectTimelineEntry | undefined> = ({
  projectId,
}) =>
  flow(getProjectTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredEntry = entries.filter(
        (entry) =>
          entry.projectId === projectId &&
          entry.snapshotTypeId === '3' &&
          entry.snapshotId === null &&
          entry.currentPeriod
      )[0];

      return filteredEntry;
    })
  );

export const getProjectCurrentPeriodPlannedPoC: ({
  projectId,
}: {
  projectId: string;
}) => (
  appState: AppState
) => remoteData.RemoteData<ProjectTimelineEntry | undefined> = ({
  projectId,
}) =>
  flow(getProjectTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredEntry = entries.filter(
        (entry) =>
          entry.projectId === projectId &&
          entry.snapshotTypeId === '2' &&
          entry.snapshotId === null &&
          entry.currentPeriod
      )[0];

      return filteredEntry;
    })
  );

export const getProjectPoCMonths: ({
  projectId,
}: {
  projectId: string;
}) => (appState: AppState) => remoteData.RemoteData<Date[]> = ({ projectId }) =>
  flow(getProjectTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const mappedMonths = entries
        .filter((entry) => entry.pastPeriod === false)
        .map((entry) => {
          return entry.date;
        });

      const distinctMonths = mappedMonths.filter(
        (date, i, self) =>
          self.findIndex((d) => d.getTime() === date.getTime()) === i
      );

      const sortedMonths = distinctMonths.sort(
        (a, b) => a.getTime() - b.getTime()
      );

      return sortedMonths;
    })
  );

export const getProjectTimelineSnapshotIds: ({
  projectId,
}: {
  projectId: string;
}) => (appState: AppState) => remoteData.RemoteData<string[]> = ({
  projectId,
}) =>
  flow(getProjectTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const mappedIds = entries
        .filter((entry) => entry.pastPeriod === true)
        .map((entry) => {
          return entry.snapshotId;
        })
        .filter(isDefined)
        .filter(isNotNull);

      const distinctIds = [...new Set(mappedIds)];

      const sortedIds = distinctIds.sort((a, b) =>
        Number(a) < Number(b) ? -1 : 1
      );

      return sortedIds;
    })
  );

export const getProjectCurrentMonth: ({
  projectId,
}: {
  projectId: string;
}) => (appState: AppState) => remoteData.RemoteData<Date> = ({ projectId }) =>
  flow(getProjectTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const currentMonth = entries.filter((entry) => entry.currentPeriod)[0]
        .date;

      return currentMonth ?? Date.now();
    })
  );

export const getProjectPlannedPoCEntries: ({
  projectId,
}: {
  projectId: string;
}) => (appState: AppState) => remoteData.RemoteData<ProjectTimelineEntry[]> = ({
  projectId,
}) =>
  flow(getProjectTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredEntries = entries.filter(
        (entry) =>
          entry.projectId === projectId &&
          entry.snapshotTypeId === '2' &&
          entry.snapshotId === null
      );

      return filteredEntries;
    })
  );

export const getProjectActualAndPlannedPoCEntries: ({
  projectId,
}: {
  projectId: string;
}) => (appState: AppState) => remoteData.RemoteData<ProjectTimelineEntry[]> = ({
  projectId,
}) =>
  flow(getProjectTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredActualEntries = entries.filter(
        (entry) =>
          entry.projectId === projectId &&
          entry.snapshotTypeId === '1' &&
          entry.snapshotId === null
      );

      const filteredPlannedEntries = entries.filter(
        (entry) =>
          entry.projectId === projectId &&
          entry.snapshotTypeId === '2' &&
          entry.snapshotId === null &&
          entry.currentPeriod === false
      );

      return filteredActualEntries.concat(filteredPlannedEntries);
    })
  );

export const getProjectPoCBasedOnPreviouslyPlannedEntries: ({
  projectId,
}: {
  projectId: string;
}) => (appState: AppState) => remoteData.RemoteData<ProjectTimelineEntry[]> = ({
  projectId,
}) =>
  flow(getProjectTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredEntries = entries.filter(
        (entry) =>
          entry.projectId === projectId &&
          entry.snapshotTypeId === '3' &&
          entry.snapshotId === null
      );

      return filteredEntries;
    })
  );

export const getProjectPreviouslyPlannedPoCEntries: ({
  projectId,
}: {
  projectId: string;
}) => (appState: AppState) => remoteData.RemoteData<ProjectTimelineEntry[]> = ({
  projectId,
}) =>
  flow(getProjectTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredEntries = entries.filter(
        (entry) =>
          entry.projectId === projectId &&
          entry.snapshotTypeId === '2' &&
          entry.latestSnapshot &&
          entry.pastPeriod === false
      );

      return filteredEntries;
    })
  );

export const getProjectSnapshotPoCEntries: ({
  projectId,
}: {
  projectId: string;
}) => (appState: AppState) => remoteData.RemoteData<ProjectTimelineEntry[]> = ({
  projectId,
}) =>
  flow(getProjectTimelineEntriesForProject(projectId), (remoteEntries) =>
    remoteData.map(remoteEntries, (entries) => {
      const filteredEntries = entries.filter(
        (entry) =>
          entry.projectId === projectId &&
          entry.snapshotId !== null &&
          entry.pastPeriod
      );

      return filteredEntries;
    })
  );
