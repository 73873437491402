import React, { useRef } from 'react';

import styled from 'styled-components';

import useTxt from '../../../../hooks/useTxt';

import {
  CenteredButtonGroup,
  PrimaryButton,
  SecondaryButton,
} from '../../../../components/Buttons';
import Modal, {
  Content,
  Header,
  Container,
  FilterTextInput,
  Footer,
} from '../../../../components/Modal/Modal';
import Txt from '../../../../components/Txt';

import { useDebouncedValue } from '../../../../utils/hooks';

import { ExtendedScheduleWorkPackageType } from '../NewScheduleTree';
import WorkPackageList from './WorkPackageList';

interface LinkWorkPackagesModalProps {
  onClose: () => void;
  scheduleWorkPackages: ExtendedScheduleWorkPackageType[];
  headerInfo: { code: string; name: string };
  selectedBasedOnWorkSection: string[];
  manualLinkedWorkPackages: string[];
  setLinkedWorkPackages: (workPackages: string[]) => void;
}

const LinkWorkPackagesModal = ({
  onClose,
  scheduleWorkPackages,
  headerInfo,
  selectedBasedOnWorkSection,
  manualLinkedWorkPackages,
  setLinkedWorkPackages,
}: LinkWorkPackagesModalProps) => {
  const [filter, setFilterText] = React.useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const [selectedPackages, setSelectedPackages] = React.useState<string[]>([
    ...new Set([...selectedBasedOnWorkSection, ...manualLinkedWorkPackages]),
  ]);

  const debouncedFilter = useDebouncedValue(filter, 1000);

  const placeHolder = useTxt(
    'worksection.workpackage.editModal.searchScheduleWorkPackages'
  );

  React.useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const toggleSelection = (ids: string[]) => {
    const childrenIds = ids
      .map((id) => {
        return scheduleWorkPackages
          .filter((wp) => wp.parents.includes(id))
          .map((wp) => wp.id);
      })
      .flat();

    const parentIds = ids
      .map((id) => {
        return scheduleWorkPackages
          .filter((wp) => wp.id === id)
          .map((wp) => wp.parents);
      })
      .flat()
      .flat();

    const uniqueParentIds = [...new Set(parentIds)];

    const selfAndChildrenIds = [...new Set([...ids, ...childrenIds])];
    const isSelfSelected = ids.every((id) => selectedPackages.includes(id));

    if (!isSelfSelected) {
      const uniqueIds = [
        ...new Set([...selectedPackages, ...selfAndChildrenIds]),
      ];

      return setSelectedPackages(uniqueIds);
    }

    const isSelfOrChildSelected = selfAndChildrenIds.some(
      (id) =>
        selectedPackages.includes(id) &&
        !selectedBasedOnWorkSection.includes(id) // exclude preselected work packages
    );

    if (isSelfOrChildSelected) {
      const selectedWithoutSelfAndChildren = selectedPackages.filter(
        (selectedId) =>
          (!selfAndChildrenIds.includes(selectedId) &&
            !uniqueParentIds.includes(selectedId)) ||
          selectedBasedOnWorkSection.includes(selectedId) // exclude preselected work packages
      );

      setSelectedPackages(selectedWithoutSelfAndChildren);
    } else {
      const uniqueIds = [
        ...new Set([...selectedPackages, ...selfAndChildrenIds]),
      ];
      setSelectedPackages(uniqueIds);
    }
  };

  return (
    <Modal onClose={onClose}>
      <StyledContainer>
        <Header>
          <Txt
            id="worksection.workpackage.editModal.linkScheduleWorkPackages"
            values={headerInfo}
          />
        </Header>
        <StyledContent noMaxHeight>
          <FilterTextInput
            name="filter"
            id="filter"
            placeholder={placeHolder}
            onChange={(e) => setFilterText(e.target.value)}
            ref={inputRef}
          />
          <WorkPackageList
            filter={debouncedFilter}
            workPackages={scheduleWorkPackages}
            onSelect={toggleSelection}
            selectedIds={selectedPackages}
            selectedIdsViaWorkSection={selectedBasedOnWorkSection}
          />
        </StyledContent>
        <Footer>
          <StyledButtonGroup>
            <SecondaryButton
              type="button"
              onClick={() => {
                setSelectedPackages(selectedBasedOnWorkSection);
                setLinkedWorkPackages([]);
              }}
            >
              <Txt id="worksection.workpackage.editModal.linkScheduleWorkPackages.unLinkButton" />
            </SecondaryButton>
            <SecondaryButton type="button" onClick={onClose}>
              <Txt id="common.cancel" />
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              onClick={() => {
                setLinkedWorkPackages(selectedPackages);
                onClose();
              }}
            >
              <Txt
                id="worksection.workpackage.editModal.linkScheduleWorkPackages.linkButton"
                values={{ count: selectedPackages.length }}
              />
            </PrimaryButton>
          </StyledButtonGroup>
        </Footer>
      </StyledContainer>
    </Modal>
  );
};

export default LinkWorkPackagesModal;

const StyledContainer = styled(Container)`
  width: 50vw;
  max-height: 80vh;
`;

const StyledContent = styled(Content)`
  padding-right: ${(props) => `${props.theme.margin[32]}`};
`;

const StyledButtonGroup = styled(CenteredButtonGroup)`
  padding: 0 ${(props) => props.theme.margin[18]};
  width: 100%;
  justify-content: right;
`;
