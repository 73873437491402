import { Reducer } from 'redux';

import { APIOrder, APITopic, APIWorkPackage } from '../../types/api';

import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
} from './utils';
import { BackendError } from '../../utils/api';
import { isPresent, isDefined } from '../../utils/general';
import groupBy from '../../utils/groupBy';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';
import { getArrivalRowById } from './arrivalRow';
import { sortItems } from './helpers/sort';
import { selectProjectOrders } from './order/order';
import { getRenderableOrderRowById } from './orderRow';
import { getTopic, getTopicsByWorkPackageId } from './topic';
import { getWorkPackageGroupById } from './workPackageGroup';

import { AppState } from '.';

export type WorkPackageState = Partial<
  Record<
    string,
    remoteData.RemoteData<
      Partial<Record<string, APIWorkPackage>>,
      BackendError | undefined
    >
  >
>;

const initialState: WorkPackageState = {};

const workPackageReducer: Reducer<WorkPackageState, ActionTypes> = (
  state = initialState,
  action
): WorkPackageState => {
  switch (action.type) {
    case 'GET_WORK_PACKAGES_STARTED': {
      const { projectId } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.loading,
      };
    }
    case 'GET_WORK_PACKAGES_FAILURE': {
      const { projectId, error } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.fail(error),
      };
    }
    case 'GET_WORK_PACKAGES_SUCCESS': {
      const { projectId, workPackages } = action.payload;
      const normalizedworkPackages = normalizeBy('id', workPackages);

      return {
        ...state,
        [projectId]: remoteData.succeed(normalizedworkPackages),
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { workPackages: updatedWorkPackages = [] } = action.payload;

    return updatedWorkPackages.reduce((nextState, workPackage) => {
      const { id, projectId, isDeleted } = workPackage;

      const { [projectId]: remoteWorkPackages = remoteData.notAsked } =
        nextState;

      return {
        ...nextState,
        [projectId]: remoteData.map(
          remoteWorkPackages,
          ({ [id]: _, ...workPackages }) =>
            isDeleted ? workPackages : { [id]: workPackage, ...workPackages }
        ),
      };
    }, state);
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export default workPackageReducer;

type WorkPackageIdentifier = {
  workPackageId: string;
  projectId: string;
};

export const selectProjectWorkPackages =
  (projectId: string) =>
  ({ workPackages: { data } }: AppState) =>
    data[projectId] ?? remoteData.notAsked;

export const getWorkPackageById =
  (workPackageId: string) =>
  ({ workPackages: { data } }: AppState): APIWorkPackage | undefined =>
    Object.values(data)
      .map((remoteWorkPackages) =>
        remoteData.unwrap(remoteWorkPackages ?? remoteData.notAsked, {
          unwrapper: ({ [workPackageId]: workPackage }) => workPackage,
          defaultValue: undefined,
        })
      )
      .find(isPresent);

type WorkSectionRow = {
  order: APIOrder;
  topics: APITopic[];
};

function toWorkSection(
  orders: Partial<Record<string, APIOrder>>,
  topics: APITopic[]
): WorkSectionRow[] {
  return Object.entries(groupBy('orderId', topics))
    .map(([orderId, orderTopics]) => {
      const order = orders[orderId];

      return order
        ? {
            order,
            topics: orderTopics,
          }
        : undefined;
    })
    .filter(isDefined);
}

export function getWorkSectionRows({
  projectId,
  workPackageId,
}: WorkPackageIdentifier): (
  appState: AppState
) => remoteData.RemoteData<WorkSectionRow[]> {
  return (appState: AppState) => {
    const remoteOrders = selectProjectOrders(projectId)(appState);
    const remoteTopics = getTopicsByWorkPackageId(workPackageId)(appState);
    const ordersAndTopics = remoteData.append(remoteOrders, remoteTopics);

    return remoteData.map(ordersAndTopics, ([orders, topics]) =>
      toWorkSection(orders, topics)
    );
  };
}

export const getProjectWorkPackages =
  (projectId: string) =>
  ({
    workPackages: { data, sortOrders },
  }: AppState): remoteData.RemoteData<APIWorkPackage[]> =>
    remoteData.map(data[projectId] ?? remoteData.notAsked, (workPackages) =>
      sortItems(Object.values(workPackages).filter(isDefined), sortOrders)
    );

export const getWorkPackagesByWorkPackageGroupId =
  (workPackageGroupId: string) =>
  (appState: AppState): APIWorkPackage[] => {
    const { sortOrders } = appState.workPackages;

    const workPackageGroup =
      getWorkPackageGroupById(workPackageGroupId)(appState);
    const workPackageIds = workPackageGroup?.workPackageIds ?? [];

    const workPackages = workPackageIds.map((id) =>
      getWorkPackageById(id)(appState)
    );

    return sortItems(workPackages.filter(isDefined), sortOrders);
  };

export const getWorkPackageCodes =
  (workPackageGroupId: string) =>
  (appState: AppState): string[] => {
    const workPackageGroup =
      getWorkPackageGroupById(workPackageGroupId)(appState);

    const workPackageIds = workPackageGroup?.workPackageIds ?? [];

    const workPackages = workPackageIds.map((id) =>
      getWorkPackageById(id)(appState)
    );

    return workPackages.filter(isDefined).map((wp) => wp.code);
  };

export const getWorkPackageByArrivalRowId =
  (arrivalRowId: string) =>
  (appState: AppState): APIWorkPackage | undefined => {
    const arrivalRow = getArrivalRowById(arrivalRowId)(appState);

    if (!arrivalRow) {
      return undefined;
    }

    const orderRow = getRenderableOrderRowById(arrivalRow.orderRowId ?? '')(
      appState
    );

    if (!orderRow) {
      return undefined;
    }

    const topic = getTopic(orderRow.topicId)(appState);

    if (!topic) {
      return undefined;
    }

    return getWorkPackageById(topic.workPackageId ?? '')(appState);
  };
