import { sortBy } from 'lodash';
import { Reducer } from 'redux';

import { BackendError } from '../../../utils/api';
import { flow } from '../../../utils/function';
import { isDefined } from '../../../utils/general';
import normalizeBy from '../../../utils/normalizeBy';
import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { TargetRowHierarchyEntry } from '../../actions/target/hierarchy';
import { ActionTypes as Action } from '../../actionTypes';
import { AppState } from '../index';

type Err = BackendError | undefined;

type State = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  data: Partial<Record<string, TargetRowHierarchyEntry>>;
};

const initialState: State = {
  requests: {},
  data: {},
};

const reducer: Reducer<State, Action> = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TARGET_ROW_HIERARCHIES_STARTED': {
      const { projectId } = action.payload;
      const requests = { ...state.requests, [projectId]: remoteData.loading };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_TARGET_ROW_HIERARCHIES_FAILURE': {
      const { projectId, error } = action.payload;

      const requests = {
        ...state.requests,
        [projectId]: remoteData.fail(error),
      };

      return { ...state, requests };
    }

    case 'GET_TARGET_ROW_HIERARCHIES_SUCCESS': {
      const { projectId, targetRowHierarchyEntries } = action.payload;

      const requests = {
        ...state.requests,
        [projectId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', targetRowHierarchyEntries),
      };

      return {
        requests,
        data,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

export function getRequestState(
  projectId: string
): Selector<remoteData.RemoteData['kind']> {
  return ({
    target: {
      hierarchies: {
        requests: { [projectId]: requestState = remoteData.notAsked },
      },
    },
  }) => requestState.kind;
}

export function isLoading(projectId: string): Selector<boolean> {
  return flow(
    getRequestState(projectId),
    (requestState) => requestState === 'Loading'
  );
}

export const getTargetRowHierarchyEntriesForProject: (
  projectId: string
) => Selector<remoteData.RemoteData<TargetRowHierarchyEntry[]>> =
  (projectId) =>
  ({
    target: {
      hierarchies: {
        requests: { [projectId]: requestState = remoteData.notAsked },
        data,
      },
    },
  }) =>
    remoteData.map(requestState, (_) =>
      Object.values(data)
        .filter(isDefined)
        .filter((entry) => entry.projectId === projectId)
    );

export function getTopLevelHierarchyEntriesByProjectId(projectId: string) {
  return flow(
    getTargetRowHierarchyEntriesForProject(projectId),
    (remoteTargetRowHierarchyEntries) =>
      remoteData.map(remoteTargetRowHierarchyEntries, (entries) => {
        const filteredEntries = entries.filter(
          (entry) => entry.projectId === projectId && entry.parentId === null
        );

        const sortedAndFiltered = sortBy(
          filteredEntries,
          (entry) => entry.referenceNumber
        );

        return sortedAndFiltered;
      })
  );
}

export function getHierarchyMaxDepth(projectId: string) {
  return flow(
    getTargetRowHierarchyEntriesForProject(projectId),
    (remoteTargetRowHierarchyEntries) =>
      remoteData.map(remoteTargetRowHierarchyEntries, (entries) => {
        const filteredEntries = entries.map((entry) => entry.depth);

        return Math.max(...filteredEntries);
      })
  );
}

export const getHierarchyEntryById =
  (projectId: string) =>
  ({
    target: {
      hierarchies: { data },
    },
  }: AppState): TargetRowHierarchyEntry | undefined => {
    return data[projectId];
  };
