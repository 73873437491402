import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { getProjectById } from '../../../store/reducers/project';
import { getActiveProjectId } from '../../../store/reducers/ui';

import useTxt from '../../../hooks/useTxt';

import { IconButton } from '../../../components/Buttons';
import { ColorBox, TaskStatusIcon } from '../../../components/ColorBox';
import ToolTip, { StyledTooltip } from '../../../components/Tooltip';

import { dateFormat } from '../../../utils/format';

import {
  IconCloseCircle,
  IconCollapseMinus,
  IconExclamationMark,
  IconExpandPlus,
  IconSchedule,
} from '../../../assets/svg';

import { generateUrl, routes } from '../../../routes';

import {
  ExpandCollapseButton,
  ExtendedScheduleWorkPackageType,
} from './NewScheduleTree';

type ScheduleTreeRowProps = {
  depth: number;
  scheduleWorkPackage: ExtendedScheduleWorkPackageType;
  allWorkPackages: ExtendedScheduleWorkPackageType[];
  allSelectedWorkPackages: string[];
  closedWorkPackageIds: string[];
  toggleRow: (id: string) => void;
  deSelectDisabledIds: string[];
  deSelectWorkPackage: (id: string) => void;
  otherExistingLinkages: {
    id: string;
    code: string;
    name: string;
    scheduleWorkPackageIds: string[];
  }[];
};

const NewScheduleTreeRow = ({
  depth,
  scheduleWorkPackage,
  allWorkPackages,
  closedWorkPackageIds,
  allSelectedWorkPackages,
  toggleRow,
  deSelectDisabledIds,
  deSelectWorkPackage,
  otherExistingLinkages,
}: ScheduleTreeRowProps) => {
  const projectId = useSelector(getActiveProjectId) ?? '';

  const history = useHistory();

  const project = useSelector(getProjectById(projectId));

  const isExpanded = !closedWorkPackageIds.includes(scheduleWorkPackage.id);

  const includedInPoC = allSelectedWorkPackages.includes(
    scheduleWorkPackage.id
  );

  const workPackageName = scheduleWorkPackage.name;

  const {
    planned_start: startDate,
    planned_end: endDate,
    progress_percentage: progress,
    teamColor,
    teamName,
    virtualSpaceName,
    workSectionClassName,
  } = scheduleWorkPackage;

  const children = allWorkPackages.filter(
    (t) => t.parent_id === scheduleWorkPackage.id
  );

  const showCollapseExpand = children.length > 0;

  const deSelectDisabled =
    deSelectDisabledIds.includes(scheduleWorkPackage.id) || !includedInPoC;

  const tipId = 'worksection.workpackage.editModal.notIncludedInPoc.tooltip';

  const tip = useTxt(
    'worksection.workpackage.editModal.notIncludedInPoc.tooltip'
  );

  const existingLinkages = otherExistingLinkages.filter((linkage) =>
    linkage.scheduleWorkPackageIds.includes(scheduleWorkPackage.id)
  );

  if (!project) {
    return null;
  }

  function scheduleLinkClicked() {
    const url = project?.externalUrl;

    if (url) {
      window.open(`${url}/work-packages/${scheduleWorkPackage.id}`, '_blank');
    }
  }

  const existingLinkagesTooltipText = existingLinkages
    .map((linkage) => `${linkage.code} ${linkage.name}`)
    .join(', ');

  const toEditMode = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    workPackageId: string
  ) => {
    e.stopPropagation();

    const url = generateUrl({
      route: routes.WORKSECTION_EXPANDED,
      projectId,
      viewMode: 'edit',
      workPackageId,
    });

    history.push(url);
  };

  return (
    <>
      <ToolTipTableRow includedInPoC={includedInPoC} tip={tip} tipId={tipId}>
        <ScheduleCell noBorder />
        <ScheduleCell colSpan={2} depth={depth}>
          <CellDiv>
            <HiddenOverflowDiv>
              {showCollapseExpand ? (
                <ExpandCollapseButton
                  icon={isExpanded ? IconCollapseMinus : IconExpandPlus}
                  customSize="12px"
                  onClick={() => toggleRow(scheduleWorkPackage.id)}
                />
              ) : (
                <EmptyDiv />
              )}
              {workPackageName}
            </HiddenOverflowDiv>
            {existingLinkages.length > 0 ? (
              <ToolTip tip={existingLinkagesTooltipText}>
                <ExistingIcon
                  icon={OrangeExclamationMark}
                  onClick={(e) => toEditMode(e, existingLinkages[0].id)}
                />
              </ToolTip>
            ) : null}
          </CellDiv>
        </ScheduleCell>
        <ScheduleCell>
          <HiddenOverflowSpan>{virtualSpaceName}</HiddenOverflowSpan>
        </ScheduleCell>
        <ScheduleCell>
          <HiddenOverflowSpan>{workSectionClassName}</HiddenOverflowSpan>
        </ScheduleCell>
        <ScheduleCell>
          <FlexDiv>
            {scheduleWorkPackage.type === 'sum' ? null : (
              <>
                <TeamColorBox color={teamColor ?? 'white'} />
                <HiddenOverflowSpan>{teamName ?? ''}</HiddenOverflowSpan>
              </>
            )}
          </FlexDiv>
        </ScheduleCell>
        <ScheduleCell>{dateFormat.format(new Date(startDate))}</ScheduleCell>
        <ScheduleCell>{dateFormat.format(new Date(endDate))}</ScheduleCell>
        <ScheduleCell>
          <FlexDiv>
            <StyledStatusIcon statusColor={scheduleWorkPackage.statusColor} />
            <HiddenOverflowSpan>{progress.toFixed(0)}%</HiddenOverflowSpan>
          </FlexDiv>
        </ScheduleCell>
        <ScheduleCell>
          <FlexDiv>
            <StyledIconButton
              icon={IconSchedule}
              onClick={scheduleLinkClicked}
            />
            <StyledIconButton
              icon={IconCloseCircle}
              disabled={deSelectDisabled}
              onClick={() => deSelectWorkPackage(scheduleWorkPackage.id)}
            />
          </FlexDiv>
        </ScheduleCell>
      </ToolTipTableRow>
      {isExpanded
        ? children.map((child) => (
            <NewScheduleTreeRow
              key={child.id}
              depth={depth + 1}
              scheduleWorkPackage={child}
              allWorkPackages={allWorkPackages}
              allSelectedWorkPackages={allSelectedWorkPackages}
              closedWorkPackageIds={closedWorkPackageIds}
              toggleRow={toggleRow}
              deSelectDisabledIds={deSelectDisabledIds}
              deSelectWorkPackage={deSelectWorkPackage}
              otherExistingLinkages={otherExistingLinkages}
            />
          ))
        : null}
    </>
  );
};

export default NewScheduleTreeRow;

type ToolTipTableRowProps = {
  children: React.ReactNode;
  includedInPoC: boolean;
  tipId: string;
  tip: string;
};

const ToolTipTableRow = ({
  children,
  includedInPoC,
  tipId,
  tip,
}: ToolTipTableRowProps) => {
  if (!includedInPoC) {
    return (
      <>
        <TableRow
          includedInPoC={includedInPoC}
          data-tip={tip}
          data-for={tipId ?? `global_${tip}`}
        >
          {children}
        </TableRow>
        {!includedInPoC ? (
          <tr>
            <td>
              <StyledTooltip
                effect="solid"
                type="dark"
                place="top"
                offset={{ top: -5 }}
                class="compressed-tooltip"
                id={tipId ?? `global_${tip}`}
                delayShow={200}
                role="tooltip"
                html
              />
            </td>
          </tr>
        ) : null}
      </>
    );
  }

  return <TableRow includedInPoC={includedInPoC}>{children}</TableRow>;
};

type CellProps = {
  depth?: number;
  noBorder?: boolean;
};

export const ScheduleCell = styled.td<CellProps>`
  border-left: none;
  border-right: ${(props) =>
    props.noBorder
      ? 'none'
      : `1px solid ${props.theme.color['M3/ref/neutral/neutral90']}`};
  border-bottom: ${(props) =>
    props.noBorder
      ? 'none'
      : `1px solid ${props.theme.color['M3/ref/neutral/neutral90']}`};
  border-top: ${(props) =>
    props.noBorder
      ? 'none'
      : `1px solid ${props.theme.color['M3/ref/neutral/neutral90']}`};

  padding-left: ${({ depth }) => depth ?? 1}rem;
  padding-right: ${(props) => props.theme.margin[8]};

  height: ${(props) => props.theme.margin[32]};
`;

const CellDiv = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`;

const HiddenOverflowDiv = styled.div`
  width: 100%;

  display: inline-block;
  align-items: center;

  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;

  :hover {
    white-space: unset;
    overflow: visible;
  }
`;

type RowProps = {
  includedInPoC: boolean;
};

const TableRow = styled.tr<RowProps>`
  background-color: ${(props) =>
    !props.includedInPoC ? props.theme.color.graphiteB91 : 'none'};
`;

const StyledIconButton = styled(IconButton)`
  margin-right: ${(props) => props.theme.margin[16]};
`;

const EmptyDiv = styled.div`
  padding: 4px;
  height: 12px;
  width: 12px;
  display: inline-block;
`;

const StyledStatusIcon = styled(TaskStatusIcon)`
  margin-right: ${(props) => props.theme.margin[8]};
  margin-bottom: ${(props) => props.theme.margin[2]};
`;

export const TeamColorBox = styled(ColorBox)`
  margin-right: ${(props) => props.theme.margin[8]};
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HiddenOverflowSpan = styled.span`
  width: 100%;

  display: inline-block;
  align-items: center;

  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;

  :hover {
    white-space: unset;
  }
`;

const ExistingIcon = styled(IconButton)`
  margin-right: ${(props) => props.theme.margin[8]};
  margin-left: auto;
`;

const OrangeExclamationMark = styled(IconExclamationMark)`
  fill: ${(props) => props.theme.color.orange};
`;
