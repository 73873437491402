import React from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import useMeasure from 'react-use-measure';

import {
  getCoreRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import styled from 'styled-components';

import { getHierarchyMaxDepth } from '../../../../store/reducers/target/hierarchy';

import useTargetViewColumns from '../../hooks/useTargetViewColumns';
import { TargetRowOrTargetRowHierarchyEntry } from '../../hooks/useTargetViewData';

import { GlobalLoadingSpinner } from '../../../../components/Loading';

import TargetViewRow from './TargetRowOrTargetRowHierarchyEntry';
import Th from './Th';

const TargetTable = ({
  projectId,
  data,
}: {
  projectId: string;
  data: TargetRowOrTargetRowHierarchyEntry[];
}) => {
  const columns = useTargetViewColumns();

  const isFetchingMaxDepth = useSelector(getHierarchyMaxDepth(projectId));

  const memoizedData = React.useMemo(() => data, [data]);

  const [measureRef, measures] = useMeasure({ debounce: 100 });
  const windowSize = useWindowSize();

  const table = useReactTable({
    data: memoizedData,
    columns,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualSorting: true,
  });

  const { rows } = table.getRowModel();

  const rowsLength = React.useMemo(() => rows.length, [rows]);

  const tableContainerRef = React.useRef<HTMLDivElement | null>(null);

  const rowVirtualizer = useVirtualizer({
    count: rowsLength,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: React.useCallback(() => 40, []), // estimate row height for accurate scrollbar dragging
    overscan: 40,
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
  });

  const measureElement = React.useCallback(
    (node: Element | null) => {
      return rowVirtualizer.measureElement(node);
    },
    [rowVirtualizer]
  );

  const containerWidth = React.useMemo(
    () => windowSize.width - measures.left - 40,
    [measures.left, windowSize.width]
  ); // 40px is the margin of target view container

  const containerHeight = React.useMemo(
    () => windowSize.height - measures.top,
    [measures.top, windowSize.height]
  );

  const virtualItems = rowVirtualizer.getVirtualItems();

  const visibleRows = React.useMemo(() => {
    return virtualItems.map((virtualRow) => ({
      ...virtualRow,
      row: rows[virtualRow.index],
    }));
  }, [virtualItems, rows]);

  if (isFetchingMaxDepth.kind !== 'Success') {
    return (
      <StyledContainer height={containerHeight} width={containerWidth}>
        <GlobalLoadingSpinner />
      </StyledContainer>
    );
  }

  return (
    <div ref={measureRef}>
      <StyledContainer
        ref={tableContainerRef}
        height={containerHeight}
        width={containerWidth}
      >
        <StyledTable>
          <StyledThead>
            {table.getHeaderGroups().map((headerGroup) => (
              <StyledTr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const totalWidth = headerGroup.headers
                    .map((h) => h.getSize())
                    .reduce((a, b) => a + b, 0);

                  const thWidth = Math.round(
                    (header.getSize() / totalWidth) * (containerWidth - 20)
                  );

                  return <Th key={header.id} header={header} width={thWidth} />;
                })}
              </StyledTr>
            ))}
          </StyledThead>
          <StyledTBody height={rowVirtualizer.getTotalSize()}>
            {visibleRows.map((virtualRow) => {
              return (
                <TargetViewRow
                  key={virtualRow.row.id}
                  row={virtualRow.row}
                  virtualRow={virtualRow}
                  measureElement={measureElement}
                  containerWidth={containerWidth - 20}
                />
              );
            })}
          </StyledTBody>
        </StyledTable>
      </StyledContainer>
    </div>
  );
};

type ContainerProps = {
  height: number;
  width: number;
};

const StyledContainer = styled.div<ContainerProps>`
  position: relative;

  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;

  overflow: auto;

  transform: translate3d(0, 0, 0);
`;

type TBodyProps = {
  height: number;
};

const StyledTBody = styled.tbody<TBodyProps>`
  position: relative;
  height: ${({ height }) => height}px;
  display: grid;
`;

const StyledTable = styled.table`
  display: grid;
  align-items: center;

  border-collapse: collapse;
  border-spacing: 0;

  table-layout: fixed;
`;

const StyledThead = styled.thead`
  position: sticky;
  top: 0;

  display: grid;

  background-color: white;

  z-index: 1;
`;

const StyledTr = styled.tr`
  border-bottom: 2px solid ${(props) => props.theme.color.rowBorder};
  height: 48px;
  width: 100%;
  display: flex;
`;

export default TargetTable;
