import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useFormik, FormikErrors } from 'formik';
import styled from 'styled-components';

import { getWorkPackageCodes } from '../../../../store/reducers/workPackage';

import {
  createWorkSection,
  getWorkPackageTimelineForProject,
  getWorkPackageGroupTimelineForProject,
  getProjectTimelineForProject,
} from '../../../../store/actions';

import {
  APIWorkPackagePostBody,
  APIUpdatedEntities,
} from '../../../../types/api';

import useTxt from '../../../../hooks/useTxt';

import {
  CenteredButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../../components/Buttons';
import { DatePicker } from '../../../../components/DatePickerInput';
import TextInput from '../../../../components/Input/TextInput';
import { Spinner } from '../../../../components/Loading';
import Modal, {
  Header,
  Container,
  Content,
} from '../../../../components/Modal/Modal';
import Txt from '../../../../components/Txt';

import { uniqueCode } from '../../../../utils/decoders';
import { MAX_YEARS_FROM_NOW } from '../../../../utils/general';

import { routes, useParams } from '../../../../routes';

type AddWorkPackageProps = {
  workPackageGroupId: string;
  onClose: () => void;
  navigateToDetailsPage: (id: string) => void;
};

const AddWorkPackageForm = ({
  workPackageGroupId,
  onClose,
  navigateToDetailsPage,
}: AddWorkPackageProps) => {
  const isRequired = useTxt('form.inputError.isRequired');
  const codeAlreadyExists = useTxt('form.inputError.codeAlreadyExists');
  const maxLength = useTxt('form.inputError.maxLength');
  const startDateError = useTxt('form.inputError.startDateError');
  const endDateError = useTxt('form.inputError.endDateError');

  const maxDateError = useTxt('form.inputError.maxDateError', {
    yearsFromNow: MAX_YEARS_FROM_NOW,
  });

  const { projectId } = useParams(routes.WORKSECTIONS);

  const dispatch = useDispatch();

  const workPackageCodes = useSelector(
    getWorkPackageCodes(workPackageGroupId || '')
  );

  type FormValues = {
    name: string;
    code: string;
    startDate: Date | string;
    endDate: Date | string;
  };

  const onAddSuccess = (data: APIUpdatedEntities) => {
    const { workPackages } = data;
    const newWorkPackage = workPackages && workPackages[0];

    if (newWorkPackage) {
      dispatch(getWorkPackageTimelineForProject({ projectId }));
      dispatch(getWorkPackageGroupTimelineForProject({ projectId }));
      dispatch(getProjectTimelineForProject({ projectId }));
      navigateToDetailsPage(newWorkPackage.id);
    }

    onClose();
  };

  const validate = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.name) {
      errors.name = isRequired;
    }

    if (!values.code) {
      errors.code = isRequired;
    }

    if (!uniqueCode(workPackageCodes).is(values.code)) {
      errors.code = codeAlreadyExists;
    }

    if (!values.startDate) {
      errors.startDate = isRequired;
    }

    if (!values.endDate) {
      errors.endDate = isRequired;
    }

    if (values.endDate && values.startDate) {
      if (values.endDate < values.startDate) {
        errors.endDate = endDateError;
        errors.startDate = startDateError;
      }
    }

    const today = new Date();

    const maxYearsFromNow = new Date(
      today.setFullYear(today.getFullYear() + MAX_YEARS_FROM_NOW)
    );
    const endDateAsDate = new Date(values.endDate);

    if (endDateAsDate > maxYearsFromNow) {
      errors.endDate = maxDateError;
    }

    if (values.name.length > 200) {
      errors.name = maxLength;
    }

    return errors;
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      code: '',
      startDate: new Date(),
      endDate: new Date(),
    },
    validate,
    onSubmit: (values) => {
      const body: APIWorkPackagePostBody = {
        name: values.name,
        code: values.code,
        startDate: values.startDate,
        endDate: values.endDate,
        workPackageGroupId,
      };

      dispatch(createWorkSection(body, onAddSuccess));
    },
  });

  // setter - handles start and end date changes
  const { setFieldValue } = formik;

  const workpackageCode = useTxt('worksection.workpackage.code');
  const workpackageName = useTxt('worksection.workpackage.name');
  const startDate = useTxt('worksection.workpackage.startDate');
  const endDate = useTxt('worksection.workpackage.endDate');

  return (
    <Modal onClose={onClose}>
      <StyledContainer>
        <Header>
          <Txt id="worksection.table.header.createWorkPackage" />
        </Header>
        <StyledContent noMaxHeight>
          <StyledForm onSubmit={formik.handleSubmit}>
            <TextInput
              label={workpackageName}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              errorMessage={formik.errors.name}
            />
            <TextInput
              label={workpackageCode}
              name="code"
              value={formik.values.code}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              errorMessage={formik.errors.code}
            />
            <StyledDatePickers>
              <DatePicker
                name="startDate"
                date={new Date(formik.values.startDate) ?? new Date()}
                label={startDate}
                onDayChange={(val) => setFieldValue('startDate', val)}
                errorMessage={formik.errors.startDate}
              />
              <DatePicker
                name="endDate"
                date={new Date(formik.values.endDate) ?? new Date()}
                label={endDate}
                onDayChange={(val) => setFieldValue('endDate', val)}
                errorMessage={formik.errors.endDate}
              />
            </StyledDatePickers>
            <CenteredButtonGroup>
              <SecondaryButton type="button" onClick={onClose}>
                <Txt id="common.cancel" />
              </SecondaryButton>
              <PrimaryButton
                type="submit"
                disabled={
                  formik.isSubmitting ||
                  !uniqueCode(workPackageCodes).is(formik.values.code)
                }
              >
                {formik.isSubmitting ? (
                  <Spinner size="1rem" light />
                ) : (
                  <Txt id="common.save" />
                )}
              </PrimaryButton>
            </CenteredButtonGroup>
          </StyledForm>
        </StyledContent>
      </StyledContainer>
    </Modal>
  );
};

export default AddWorkPackageForm;

const StyledForm = styled.form`
  margin-top: ${(props) => props.theme.margin[16]};
`;

const StyledDatePickers = styled.div`
  /* stylelint-disable selector-max-class  -- Third party libraries need to be styled either by type or by classnames */
  /* stylelint-disable selector-max-type  -- Temp disable, TODO find better solution if any */
  /* stylelint-disable max-nesting-depth  -- disable temporary until good solution is found */
  .DayPickerInput {
    width: 100%;

    input {
      border-radius: ${(props) => props.theme.margin[2]};
      border: 1px solid ${(props) => props.theme.color.inputBorder};

      padding: ${(props) => `0 ${props.theme.margin[16]}`};

      height: ${(props) => props.theme.margin[40]};
      width: 100%;
    }
  }
`;

const StyledContainer = styled(Container)`
  overflow: inherit;
`;

const StyledContent = styled(Content)`
  border-radius: 0 0 0.5rem 0.5rem;
`;
