import { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { AppState } from '../store/reducers';

import * as remoteData from '../utils/remoteData';
import { Thunk } from '../utils/thunk';

/**
 * @param selector Selector to use
 * @param fetchData Thunk to call if data has not been asked
 * @param callCondition To skip calling data fetch, set to false. Can be used to conditionally call hooks. Defaults to true
 */
export default function useRemoteData<A, E>(
  selector: (appState: AppState) => remoteData.RemoteData<A, E>,
  fetchData: Thunk,
  callCondition: boolean = true
) {
  const dispatch = useDispatch();
  const data = useSelector(selector, shallowEqual);

  useEffect(() => {
    if (callCondition && data.kind === 'NotAsked') {
      dispatch(fetchData);
    }
  }, [fetchData, data.kind, dispatch, callCondition]);

  return data.kind === 'Success' ? data.value : undefined;
}

/**
 * This hook is used to get the request state of the remote data.
 * redux useSelector comparison function is used to prevent unnecessary re-renders but is not good with complex objects / arrays
 *
 * @param selector RemoteData Selector to use
 * @param fetchData Thunk to call if data has not been asked
 * @param callCondition To skip calling data fetch, set to false. Can be used to conditionally call hooks. Defaults to true
 */
export function useRemoteDataKind<A, E>(
  origSelector: (appState: AppState) => remoteData.RemoteData<A, E>,
  fetchData: Thunk,
  callCondition: boolean = true
) {
  const dispatch = useDispatch();

  const lightSelector = (appState: AppState) => {
    const data = origSelector(appState);

    return data.kind;
  };

  const kind = useSelector(lightSelector, shallowEqual);

  useEffect(() => {
    if (callCondition && kind === 'NotAsked') {
      dispatch(fetchData);
    }
  }, [fetchData, kind, dispatch, callCondition]);

  return kind;
}
