import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Form, Formik } from 'formik';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';

import { InvoiceHeader } from '../../../../../store/reducers/invoiceHeader';
import {
  isSentSuccessfully,
  isLoading,
} from '../../../../../store/reducers/purchaseInvoiceReclamation';
import { getReclaimTemplateState } from '../../../../../store/reducers/purchaseInvoiceReclamationTemplate';
import {
  getUserEmail,
  selectUserLanguage,
} from '../../../../../store/reducers/user';

import { requestInvoiceAttachmentFilesForOrder } from '../../../../../store/actions/invoiceAttachmentFiles';
import { fileInvoiceComplaintHeader } from '../../../../../store/actions/purchaseInvoiceReclamation';
import { fetchReclamationTemplateFields } from '../../../../../store/actions/purchaseInvoiceReclamationTemplate';

import useRouteParams from '../../../../../hooks/useRouteParams';
import useTxt from '../../../../../hooks/useTxt';

import {
  ButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../../../components/Buttons';
import FileUpload from '../../../../../components/FileUpload/FileUpload';
import FormikTextArea from '../../../../../components/Input/FormikTextArea';
import {
  RowSelectLabel,
  RowSelect,
} from '../../../../../components/Input/Select';
import TextInput, {
  ErrorMessage,
} from '../../../../../components/Input/TextInput';
import { Spinner } from '../../../../../components/Loading';
import Modal, { Content, Header } from '../../../../../components/Modal/Modal';

type FileInvoiceComplaintModalProps = {
  invoiceHeader: InvoiceHeader;
  onClose: () => void;
};

export const FileInvoiceComplaintModal = ({
  onClose,
  invoiceHeader,
}: React.PropsWithChildren<FileInvoiceComplaintModalProps>) => {
  const { orderId } = useRouteParams();

  const [uploadData, setUploadData] = useState<{ files: File[] }>({
    files: [],
  });

  const dispatch = useDispatch();
  const emailTemplate = useSelector(getReclaimTemplateState());
  const language = useSelector(selectUserLanguage) ?? '1';

  const isComplaintSent = useSelector(isSentSuccessfully(invoiceHeader.id));
  const isComplaLoading = useSelector(isLoading(invoiceHeader.id));

  useEffect(() => {
    if (emailTemplate.request.kind === 'NotAsked') {
      dispatch(fetchReclamationTemplateFields(language, invoiceHeader.id));
    }

    if (isComplaintSent) {
      onClose();
    }
  }, [
    emailTemplate.request.kind,
    dispatch,
    invoiceHeader.id,
    language,
    isComplaintSent,
    onClose,
  ]);

  const suplierFieldPlaceholderTxt = useTxt(
    'order.receiveMode.complaintModal.formField.placeholder.supplier'
  );

  const emailCcFieldPlaceholderTxt = useTxt(
    'order.receiveMode.complaintModal.formField.placeholder.cc'
  );

  const responseFieldPlaceholderTxt = useTxt(
    'order.receiveMode.complaintModal.formField.placeholder.response'
  );

  const optionPlaceholderTxt = useTxt(
    'order.receiveMode.complaintModal.formField.placeholder.reason'
  );

  const emailTitleFieldPlaceholderTxt = useTxt(
    'order.receiveMode.complaintModal.formField.placeholder.emailTitle'
  );

  const emailContentFieldPlaceholderTxt = useTxt(
    'order.receiveMode.complaintModal.formField.placeholder.emailContent'
  );

  const reasonLabelTxt = useTxt('order.receiveMode.complaintModal.reason');

  const reasonOptions = emailTemplate.data.map((template) => template.reason);

  const userEmail = useSelector(getUserEmail);

  const modalTitleText = useTxt('order.receiveMode.complaintModal.title');

  const receiverTxt = useTxt('order.receiveMode.complaintModal.receiver');
  const copyTxt = useTxt('order.receiveMode.complaintModal.copyAddress');
  const emailTittleTxt = useTxt('order.receiveMode.complaintModal.emailTitle');

  const emailContentTxt = useTxt(
    'order.receiveMode.complaintModal.emailContent'
  );

  const cancelText = useTxt('common.cancel');
  const sendText = useTxt('common.send');
  const responseText = useTxt('order.receiveMode.complaintModal.response');

  const updateData = (newFiles: File[]) =>
    setUploadData({ ...uploadData, files: newFiles });

  const receiverFieldRequired = useTxt(
    'order.receiveMode.invoiceCorrection.formField.required.receiver'
  );

  const responseFieldRequired = useTxt(
    'order.receiveMode.invoiceCorrection.formField.required.response'
  );

  const emailTitleFieldRequired = useTxt(
    'order.receiveMode.invoiceCorrection.formField.required.emailTitle'
  );

  const emailContentFieldRequired = useTxt(
    'order.receiveMode.invoiceCorrection.formField.required.emailContent'
  );

  const reasonFieldRequired = useTxt(
    'order.receiveMode.invoiceCorrection.formField.required.reason'
  );

  const validateFormFields = Yup.object().shape({
    receiver: Yup.string().required(receiverFieldRequired),
    emailTitle: Yup.string().required(emailTitleFieldRequired),
    emailContent: Yup.string().required(emailContentFieldRequired),
    reason: Yup.string().required(reasonFieldRequired),
    response: Yup.string().required(responseFieldRequired),
  });

  interface FormValues {
    receiver: string;
    copyAddress: string;
    emailTitle: string;
    emailContent: string;
    reason: string;
    response: string;
  }

  const initialValues: FormValues = {
    receiver: '',
    copyAddress: userEmail,
    emailTitle: '',
    emailContent: '',
    reason: '',
    response: userEmail,
  };

  const updateEmailFields = (
    value: number,
    setFieldValue: (field: string, value: string) => void
  ) => {
    requestAnimationFrame(() => {
      setFieldValue('emailTitle', emailTemplate.data[value].title);
    });
    setFieldValue('emailContent', emailTemplate.data[value].message);
  };

  const onSubmit = (values: FormValues) => {
    dispatch(
      fileInvoiceComplaintHeader(
        invoiceHeader.id,
        {
          receiverEmail: values.receiver,
          ccEmail: values.copyAddress,
          responseEmail: values.response,
          reason: values.reason,
          emailTitle: values.emailTitle,
          emailMessage: values.emailContent,
        },
        uploadData.files,
        () => dispatch(requestInvoiceAttachmentFilesForOrder(orderId))
      )
    );
  };

  return (
    <Modal onClose={onClose}>
      <FormContainer>
        <Header>
          <h2>
            {modalTitleText} | {invoiceHeader.supplierName}
          </h2>
        </Header>
        <StyledContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validateFormFields}
            onSubmit={onSubmit}
            validateOnMount
          >
            {({
              errors,
              values,
              setFieldValue,
              isSubmitting,
              isValid,
              handleChange,
            }) => {
              return (
                <Form>
                  <TextInput
                    paddingBottom="0"
                    label={receiverTxt}
                    name="receiver"
                    value={values.receiver}
                    onChange={handleChange}
                    errorMessage={errors.receiver}
                    placeholder={suplierFieldPlaceholderTxt}
                  />
                  <TextInput
                    paddingBottom="0"
                    label={copyTxt}
                    name="copyAddress"
                    value={values.copyAddress}
                    onChange={handleChange}
                    errorMessage={errors.copyAddress}
                    placeholder={emailCcFieldPlaceholderTxt}
                  />
                  <TextInput
                    paddingBottom="0"
                    label={responseText}
                    name="response"
                    value={values.response}
                    onChange={handleChange}
                    errorMessage={errors.response}
                    placeholder={responseFieldPlaceholderTxt}
                  />
                  <StyledRowSelectLabel htmlFor="reason-select">
                    <Label invalid={!!errors.reason}>{reasonLabelTxt}</Label>
                    <StyledRowSelect
                      required
                      name="reason"
                      value={
                        values.reason !== ''
                          ? reasonOptions.indexOf(values.reason)
                          : ''
                      }
                      onChange={(event) => {
                        const indexNumber = parseInt(event.target.value, 10);
                        const selectedValue = reasonOptions[indexNumber];
                        setFieldValue('reason', selectedValue);
                        updateEmailFields(indexNumber, setFieldValue);
                      }}
                      invalid={!!errors.reason}
                    >
                      <option value="" disabled>
                        {optionPlaceholderTxt}
                      </option>
                      {reasonOptions.map((reasonOption, index) => (
                        <option key={reasonOption} value={index}>
                          {reasonOption}
                        </option>
                      ))}
                    </StyledRowSelect>
                    {errors.reason ? (
                      <ErrorMessage>{errors.reason}</ErrorMessage>
                    ) : null}
                  </StyledRowSelectLabel>
                  <TextInput
                    paddingBottom="1rem"
                    label={emailTittleTxt}
                    name="emailTitle"
                    value={values.emailTitle}
                    onChange={handleChange}
                    errorMessage={errors.emailTitle}
                    placeholder={emailTitleFieldPlaceholderTxt}
                  />
                  <FormikTextArea
                    label={emailContentTxt}
                    name="emailContent"
                    id="emailContent"
                    placeholder={emailContentFieldPlaceholderTxt}
                  />
                  <FileUpload
                    additionalInfoTextId="order.receiveMode.complaintModal.fileUpload.note"
                    lowerHeight
                    multiple
                    updateFilesCb={updateData}
                  />
                  <CenteredButtonGroup>
                    <SecondaryButton type="button" onClick={onClose}>
                      {cancelText}
                    </SecondaryButton>
                    <PrimaryButton
                      type="submit"
                      disabled={!isValid || isSubmitting}
                    >
                      {isComplaLoading ? <Spinner size="1rem" /> : sendText}
                    </PrimaryButton>
                  </CenteredButtonGroup>
                </Form>
              );
            }}
          </Formik>
        </StyledContent>
      </FormContainer>
    </Modal>
  );
};

interface LabelProps {
  invalid: boolean;
}

const Label = styled.label<LabelProps>`
  ${(props) =>
    props.invalid &&
    css`
      color: red;
    `}
`;

const StyledRowSelectLabel = styled(RowSelectLabel)`
  margin-top: 1rem;
  display: block;
`;

interface StyledRowSelectProps {
  invalid: boolean;
}

const StyledRowSelect = styled(RowSelect)<StyledRowSelectProps>`
  margin-left: 0;
  padding: 0.5rem;
  width: 100%;

  ${(props) =>
    props.invalid &&
    css`
      border-color: red;
    `}
`;

const FormContainer = styled.div`
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
  width: 50vw;
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled(Content)`
  border-radius: 0 0 ${({ theme }) => theme.radius.medium}
    ${({ theme }) => theme.radius.medium};
  padding: ${(props) => `${props.theme.margin[32]}`};
  max-height: 64rem;
`;

const CenteredButtonGroup = styled(ButtonGroup)`
  justify-content: center;
`;
