import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Big from 'big.js';
import styled from 'styled-components';

import { getProject } from '../../../store/reducers/project';
import {
  getProjectCurrentPeriodActualPoC,
  getProjectCurrentPeriodLatestSnapshotPlannedPoC,
} from '../../../store/reducers/schedule/projectTimeline';
import {
  getAllProjectWorkPackageGroupsAreOpen,
  getColumns,
} from '../../../store/reducers/ui';
import { getWorkPackageGroupsByProjectId } from '../../../store/reducers/workPackageGroup';
import { getSortOrderFor } from '../../../store/reducers/workSection/sortWorkSections';

import { fetchProjects } from '../../../store/actions/project';
import { getProjectTimelineForProject } from '../../../store/actions/schedule/projectTimeline';
import {
  uiStateCloseAllWorkPackageGroups,
  uiStateOpenSeveralWorkPackageGroups,
  updateWorkSectionViewColumns,
} from '../../../store/actions/ui';
import { workSectionSortOrderToggled } from '../../../store/actions/workPackage';

import { SelectOption } from '../../../types/ui';

import useRemoteData from '../../../hooks/useRemoteData';
import useTxt from '../../../hooks/useTxt';

import {
  BigPriceValue,
  DeltaBigCostValue,
  BigPercentageValue,
  DeltaBigPercentageValue,
  DeltaBigProfitValue,
} from '../../../components/BigValue';
import { IconButton } from '../../../components/Buttons';
import ColumnSettings from '../../../components/MultiSelect/MultiSelect';
import {
  TableHeader,
  StickyHeaderTh,
  HeaderNumber,
  HeaderName,
} from '../../../components/Table';
import Txt from '../../../components/Txt';

import {
  IconDoubleRight,
  IconDoubleDown,
  IconSortDown,
  IconSortUp,
  IconCogwheel,
  IconCloseBlack,
} from '../../../assets/svg';

import { TextId } from '../../../localization';

import {
  workSectionColumns,
  getWidths,
  ColumnType,
} from '../../../store/localStorage';

type WorksectionTableProps = {
  projectId: string;
};

const getColumnPercent = getWidths(workSectionColumns);

type PriceValueProps = {
  price: Big | null | undefined;
  styling:
    | 'price'
    | 'priceDelta'
    | 'empty'
    | 'percentage'
    | 'percentageDelta'
    | 'profitDelta';
};

const PriceValue = ({ price, styling }: PriceValueProps) => {
  if (!price) {
    return null;
  }

  switch (styling) {
    case 'percentage':
      return (
        <HeaderNumber>
          <BigPercentageValue value={price} decimals={0} />
        </HeaderNumber>
      );
    case 'percentageDelta':
      return (
        <HeaderNumber>
          <DeltaBigPercentageValue value={price} decimals={0} />
        </HeaderNumber>
      );
    case 'price':
      return (
        <HeaderNumber>
          <BigPriceValue value={price} decimals={0} />
        </HeaderNumber>
      );
    case 'priceDelta':
      return (
        <HeaderNumber>
          <DeltaBigCostValue value={price} decimals={0} />
        </HeaderNumber>
      );
    case 'profitDelta':
      return (
        <HeaderNumber>
          <DeltaBigProfitValue value={price} decimals={0} />
        </HeaderNumber>
      );
    default:
      return null;
  }
};

export const WorksectionTableHeader = ({
  projectId,
}: WorksectionTableProps) => {
  const { workSectionViewColumns } = useSelector(getColumns);
  const project = useRemoteData(getProject(projectId), fetchProjects());

  const [showSettings, setShowSettings] = React.useState<boolean>(false);

  const actualPoC = useRemoteData(
    getProjectCurrentPeriodActualPoC({
      projectId,
    }),
    getProjectTimelineForProject({ projectId })
  );

  const latestSnapshotPoC = useRemoteData(
    getProjectCurrentPeriodLatestSnapshotPlannedPoC({
      projectId,
    }),
    getProjectTimelineForProject({ projectId })
  );

  const percentageOfCompletion = actualPoC
    ? actualPoC.percentageOfCompletion
    : new Big(0);

  const latestSnapshotPercentageOfCompletion = latestSnapshotPoC
    ? latestSnapshotPoC.percentageOfCompletion
    : new Big(0);

  const differenceToPreviousPoC = percentageOfCompletion.sub(
    latestSnapshotPercentageOfCompletion
  );

  const totals: Partial<Record<TextId, Big | null>> = {
    'worksection.table.header.percentageOfCompletion': percentageOfCompletion,
    'worksection.table.header.differenceToPreviousPoC': differenceToPreviousPoC,
    'worksection.table.header.targetTotal':
      project?.targetTotal ?? new Big('0'),
    'worksection.table.header.additionalTargetTotal':
      project?.additionalTargetTotal ?? new Big('0'),
    'worksection.table.header.predictionTotal':
      project?.costPredictionTotal ?? new Big('0'),
    'worksection.table.header.receivedTotal':
      project?.receivedTotal ?? new Big('0'),
    'worksection.table.header.predictionChangeFromLatest':
      project?.costPredictionChangeFromLatest ?? new Big('0'),
    'worksection.table.header.contractTotal':
      project?.contractTotal ?? new Big('0'),
    'worksection.table.header.changeOrdersTotal':
      project?.changeOrdersTotal ?? new Big('0'),
    'worksection.table.header.reservesTotal':
      project?.reservesTotal ?? new Big('0'),
    'worksection.table.header.remaining': project
      ? (project.costPredictionTotal ?? new Big(0)).minus(
          project.receivedTotal ?? new Big(0)
        )
      : new Big('0'),
    'worksection.table.header.differenceToTarget': project
      ? (project.targetTotal ?? new Big(0)).minus(
          project.costPredictionTotal ?? new Big(0)
        )
      : new Big('0'),
  };

  const toggleSetting = () => {
    setShowSettings(!showSettings);
  };

  return (
    <TableHeader>
      <tr>
        {workSectionViewColumns
          .filter((item) => item.selected)
          .map((header) => {
            const totalsWithEmptys = {
              ...totals,
              'worksection.table.header.code': null,
              'worksection.table.header.name': null,
              'worksection.table.header.personResponsible': null,
              'worksection.table.header.none': null,
            };

            return (
              <StickyHeaderTh
                key={header.id}
                width={`${header.width ?? `${getColumnPercent(header.id)}%`}`}
                textAlign={header.align}
              >
                <HeaderContent
                  column={header}
                  totals={totalsWithEmptys}
                  projectId={projectId}
                  showSettings={showSettings}
                  toggleSettings={toggleSetting}
                />
              </StickyHeaderTh>
            );
          })}
      </tr>
    </TableHeader>
  );
};

type HeaderContentProps = {
  column: ColumnType;
  totals: Partial<Record<TextId, Big | null>>;
  projectId: string;
  showSettings: boolean;
  toggleSettings: () => void;
};

const HeaderContent = ({
  column,
  totals,
  projectId,
  showSettings,
  toggleSettings,
}: HeaderContentProps) => {
  const dispatch = useDispatch();
  const { workSectionViewColumns } = useSelector(getColumns);

  const showHideColumns = useTxt('common.showHide');

  const allGroups = useSelector(getWorkPackageGroupsByProjectId(projectId));

  const onUpdateColumn = (selectedColumn: SelectOption) => {
    dispatch(updateWorkSectionViewColumns(selectedColumn));
  };

  const allGroupsOpen = useSelector(
    getAllProjectWorkPackageGroupsAreOpen(projectId)
  );

  const toggleAllOpenStatus = () => {
    if (allGroupsOpen) {
      dispatch(uiStateCloseAllWorkPackageGroups(projectId));
    } else {
      dispatch(
        uiStateOpenSeveralWorkPackageGroups(allGroups.map((area) => area.id))
      );
    }
  };

  switch (column.name) {
    case 'worksection.table.header.code':
      return (
        <GroupOpenButton
          groupsOpen={allGroupsOpen}
          toggleAllOpenStatus={toggleAllOpenStatus}
        />
      );
    case 'worksection.table.header.none':
      return (
        <>
          <StyledIconButton
            onClick={toggleSettings}
            icon={showSettings ? IconCloseBlack : IconCogwheel}
            id="workSectionView.button.columnSettings"
            data-cy="column-settings"
          />
          {showSettings ? (
            <ColumnSettings
              toggleShow={toggleSettings}
              container={ColumnSettingsContainer}
              listItems={{
                title: showHideColumns,
                listItemsArray:
                  workSectionViewColumns.filter(
                    (item) => item.name !== 'worksection.table.header.none'
                  ) ?? [],
              }}
              onChange={onUpdateColumn}
            />
          ) : null}
        </>
      );
    default:
      return (
        <>
          <HeaderName>
            <Txt id={column.name} />
          </HeaderName>
          <PriceValue
            price={totals[column.name]}
            styling={column.numberStyling ?? 'empty'}
          />
        </>
      );
  }
};

type GroupButtonProps = {
  groupsOpen: boolean;
  toggleAllOpenStatus: () => void;
};

const GroupOpenButton = ({
  groupsOpen,
  toggleAllOpenStatus,
}: GroupButtonProps) => {
  const allOpenText = useTxt('worksection.workPackage.groups.open');
  const allClosedText = useTxt('worksection.workPackage.groups.closed');

  const sortAscText = useTxt('worksection.workPackage.groups.sortAsc');
  const sortDescText = useTxt('worksection.workPackage.groups.sortDesc');

  const dispatch = useDispatch();

  const sortOrder = useSelector(getSortOrderFor('code'));

  const toggleSort = () => {
    if (!groupsOpen) {
      toggleAllOpenStatus();
    }

    dispatch(workSectionSortOrderToggled({ sortableKey: 'code' }));
  };

  return (
    <GroupButtons>
      <IconButton
        icon={groupsOpen ? IconDoubleDown : IconDoubleRight}
        onClick={toggleAllOpenStatus}
        aria-label={groupsOpen ? allOpenText : allClosedText}
      />
      <IconButton
        icon={sortOrder === 'Ascending' ? IconSortDown : IconSortUp}
        aria-label={sortOrder === 'Ascending' ? sortAscText : sortDescText}
        onClick={toggleSort}
      />
    </GroupButtons>
  );
};

export default WorksectionTableHeader;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: ${(props) => props.theme.margin[10]};
  top: 30px;

  border: 1px solid;
  border-radius: 50%;

  padding: ${(props) => props.theme.margin[4]};

  height: ${(props) => props.theme.margin[24]};
  width: ${(props) => props.theme.margin[24]};

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.color.graphiteB86};

  z-index: 3;
`;

const ColumnSettingsContainer = styled.div`
  position: absolute;
  right: ${(props) => props.theme.margin[2]};
  top: ${(props) => props.theme.margin[20]};

  border: 1px solid lightgrey;
  border-radius: 5px 0 5px 2px;
  box-shadow: 5px 7px 11px -12px ${(props) => props.theme.color.black};

  min-width: 16rem;

  background: ${(props) => props.theme.color.graphiteB96A};
`;

const GroupButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.51rem;
`;
