import React from 'react';
import { useHistory } from 'react-router-dom';

import { Row } from '@tanstack/react-table';
import styled from 'styled-components';

import useTxt from '../../../../hooks/useTxt';
import { TargetRowOrTargetRowHierarchyEntry } from '../../hooks/useTargetViewData';

import ArrowIcon from '../../../../components/ArrowIcon';
import ToolTip from '../../../../components/Tooltip';

import { isClickOrKeyboardSelection } from '../../../../utils/mouseOrKeyInteraction';

import { IconSplitRow } from '../../../../assets';

import { generateUrl, routes } from '../../../../routes';

type TargetRowDescriptionProps = {
  row: Row<TargetRowOrTargetRowHierarchyEntry>;
};

const TargetRowDescription = ({ row }: TargetRowDescriptionProps) => {
  const history = useHistory();
  const value = row.original.description;

  const splitToTargetRowInfo = row.original.splitTo
    ?.map((targetRow) => {
      return `${targetRow.description} (${targetRow.orderVisibleCodeAndName})`;
    })
    .join(', ');

  const splitTargetTip = useTxt(
    'order.targetMode.splitIcon.tooltip.splitFrom',
    {
      targetRowInfo: `${row.original.splitFrom?.referenceNumber ?? ''} ${
        row.original.splitFrom?.description
      } (${row.original.splitFrom?.orderVisibleCodeAndName})`,
    }
  );

  const disabledSplitTargetTip = useTxt(
    'order.targetMode.splitIcon.tooltip.disabled',
    { targetRowInfo: splitToTargetRowInfo ?? '' }
  );

  const navigateToSplitTargetView = () => {
    const splitIds: string[] = [row.original.originalId];

    const { projectId } = row.original;

    if (
      row.original.isSplitFrom &&
      row.original.isDisabled &&
      row.original.splitFrom &&
      row.original.splitTo
    ) {
      splitIds.push(
        row.original.splitFrom?.id,
        ...row.original.splitTo.map((targetRow) => targetRow.id)
      );
    } else if (row.original.isSplitFrom && row.original.splitFrom) {
      splitIds.push(row.original.splitFrom?.id);
    } else if (row.original.splitTo) {
      splitIds.push(...row.original.splitTo.map((targetRow) => targetRow.id));
    }

    if (splitIds.length > 0) {
      history.push(
        generateUrl({
          route: routes.TARGET_WITH_TARGET_ROW_FOCUSED,
          projectId,
          targetRowIds: splitIds.toString(),
        })
      );
    }
  };

  const onSplitIconPress = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      navigateToSplitTargetView();
    }
  };

  const tip = () => {
    if (row.original.isSplitFrom && row.original.isDisabled) {
      return `${splitTargetTip} & ${disabledSplitTargetTip}`;
    }

    if (row.original.isSplitFrom) {
      return splitTargetTip;
    }

    return disabledSplitTargetTip;
  };

  return (
    <>
      <EmptyIconDiv depth={row.depth} />
      {row.getCanExpand() ? (
        <ArrowIcon
          isOpen={row.getIsExpanded()}
          openAltTextKey="target.table.hierarchies.open"
          closedAltTextKey="target.table.hierarchies.closed"
        />
      ) : (
        <EmptyIconDiv depth={1} />
      )}
      <NoWrapSpan>{value}</NoWrapSpan>
      {row.original.isSplitFrom || row.original.isDisabled ? (
        <ToolTip
          tip={tip()}
          tipId={`split-icon-${row.original.id}`}
          className="hoverable-tooltip"
          delayHide={50}
          place="right"
        >
          <StyledIcon
            src={IconSplitRow}
            onClick={onSplitIconPress}
            onKeyPress={onSplitIconPress}
            alt="split_icon"
          />
        </ToolTip>
      ) : null}
    </>
  );
};

export default TargetRowDescription;

type EmptyIconDivProps = {
  depth: number;
};

const EmptyIconDiv = styled.div<EmptyIconDivProps>`
  width: ${({ depth }) => depth * 16}px;
  height: 16px;
  flex-shrink: 0;
`;

const StyledIcon = styled.img`
  margin: 0 ${({ theme }) => theme.margin[8]};

  height: 16px;
  width: 16px;

  flex-shrink: 0;

  cursor: pointer;
`;

const NoWrapSpan = styled.span`
  flex-shrink: 2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
