import { generatePath } from 'react-router-dom';
import * as reactRouterDom from 'react-router-dom';

export enum routes {
  HOME = '/',
  LOGIN = '/login',
  LOGIN_FINISH_SIGN_IN = '/login/finish-sign-in',
  LOGIN_WITH_EMAIL = '/login/email-login',
  LOGIN_EMAIL_SENT = '/login/email-sent',
  ANALYSIS = '/project/:projectId/analysis/:viewMode(edit)?',
  ORDER = '/project/:projectId/order/:orderId/:viewMode(edit|receive|comments)?/:subViewMode(invoices)?/:showTargetRows(showTarget)?/:showInfo(info)?',
  ORDER_WITH_ORDER_ROW_FOCUSED = '/project/:projectId/order/:orderId/:viewMode(edit|receive|comments)?/:subViewMode(invoices)?/:showTargetRows(showTarget)?/:showInfo(info)?#orderRowId-:orderRowId',
  ORDER_WITH_INVOICE_FOCUSED = '/project/:projectId/order/:orderId/:viewMode(receive)/:subViewMode(invoices)/:showTargetRows(showTarget)?/:showInfo(info)?#invoiceHeaderId-:invoiceHeaderId',
  ORDER_WITH_ACTUAL_COST_FOCUSED = '/project/:projectId/order/:orderId/:viewMode(receive)/:subViewMode(invoices)/:showTargetRows(showTarget)?/:showInfo(info)?#actualCostId-:actualCostId',
  ORDER_WITH_OPEN_TOPIC = '/project/:projectId/order/:orderId/:viewMode(edit|receive|comments)?/:subViewMode(invoices)?/:showTargetRows(showTarget)?/:showInfo(info)?#topic-:topicId',
  PROJECT = '/project/:projectId',
  REVENUE = '/project/:projectId/revenue/:viewMode(edit)?',
  REVENUE_WITH_ORDER_ROW_FOCUSED = '/project/:projectId/revenue/:viewMode(edit)?/#revenueId-:revenueId',
  REPORTING = '/project/:projectId/reporting',
  TARGET = '/project/:projectId/target',
  TARGET_WITH_TARGET_ROW_FOCUSED = '/project/:projectId/target#targetRowIds-:targetRowIds',
  WORKSECTIONS = '/project/:projectId/worksection',
  WORKSECTION_EXPANDED = '/project/:projectId/worksection/:workPackageId/details/:viewMode(edit)?',
  WORKSECTION_EXPANDED_WITH_TOPIC_OPEN = '/project/:projectId/worksection/:workPackageId/details/:viewMode(edit)?#topic-:topicId',
}

interface BaseParams {
  projectId: string;
}
interface HomeParams {
  route: routes.HOME;
}

interface LoginParams {
  route: routes.LOGIN;
}

interface LoginFinishSignInParams {
  route: routes.LOGIN_FINISH_SIGN_IN;
}

interface LoginWithEmailParams {
  route: routes.LOGIN_WITH_EMAIL;
}

interface LoginEmailSentParams {
  route: routes.LOGIN_EMAIL_SENT;
}

interface AnalysisParams extends BaseParams {
  route: routes.ANALYSIS;
  viewMode?: 'edit';
}

export interface OrderParams extends BaseParams {
  route: routes.ORDER;
  orderId: string;
  viewMode?: 'edit' | 'receive' | 'comments';
  subViewMode?: 'invoices';
  showTargetRows?: 'showTarget';
  showInfo?: 'info';
}

interface OrderWithOrderRowFocusedParams extends BaseParams {
  route: routes.ORDER_WITH_ORDER_ROW_FOCUSED;
  orderId: string;
  viewMode?: 'edit' | 'receive' | 'comments';
  subViewMode?: 'invoices';
  showTargetRows?: 'showTarget';
  showInfo?: 'info';
  orderRowId: string;
}

interface OrderWithInvoiceFocusedParams extends BaseParams {
  route: routes.ORDER_WITH_INVOICE_FOCUSED;
  orderId: string;
  viewMode: 'receive';
  subViewMode: 'invoices';
  showTargetRows?: 'showTarget';
  showInfo?: 'info';
  invoiceHeaderId: string;
}

interface OrderWithActualCostFocusedParams extends BaseParams {
  route: routes.ORDER_WITH_INVOICE_FOCUSED;
  orderId: string;
  viewMode: 'receive';
  subViewMode: 'invoices';
  showTargetRows?: 'showTarget';
  showInfo?: 'info';
  actualCostId: string;
}

interface RevenueWithOrderRowFocusedParams extends BaseParams {
  route: routes.REVENUE_WITH_ORDER_ROW_FOCUSED;
  revenueId: string;
  viewMode?: 'edit';
  orderId: string;
}

interface ProjectParams extends BaseParams {
  route: routes.PROJECT;
}

interface OrderWithTopicParams extends BaseParams {
  route: routes.ORDER_WITH_OPEN_TOPIC;
  orderId: string;
  viewMode?: 'edit' | 'receive' | 'comments';
  subViewMode?: 'invoices';
  showTargetRows?: 'showTarget';
  showInfo?: 'info';
  topicId: string;
}

interface RevenueParams extends BaseParams {
  route: routes.REVENUE;
  viewMode?: 'edit';
}

interface ReportingParams extends BaseParams {
  route: routes.REPORTING;
}

interface TargetParams extends BaseParams {
  route: routes.TARGET;
}

interface TargetWithTargetRowFocusedParams extends BaseParams {
  route: routes.TARGET_WITH_TARGET_ROW_FOCUSED;
  targetRowIds: string;
}

interface WorksectionsParams extends BaseParams {
  route: routes.WORKSECTIONS;
}

interface WorksectionsExpandedParams extends BaseParams {
  route: routes.WORKSECTION_EXPANDED;
  workPackageId: string;
  viewMode?: 'edit';
}

interface WorksectionExpandedWithOrderParams extends BaseParams {
  route: routes.WORKSECTION_EXPANDED_WITH_TOPIC_OPEN;
  workPackageId: string;
  topicId: string;
  viewMode?: 'edit';
}

type UrlParams =
  | HomeParams
  | LoginParams
  | LoginFinishSignInParams
  | LoginWithEmailParams
  | LoginEmailSentParams
  | AnalysisParams
  | OrderParams
  | OrderWithOrderRowFocusedParams
  | OrderWithTopicParams
  | ProjectParams
  | RevenueParams
  | RevenueWithOrderRowFocusedParams
  | ReportingParams
  | TargetParams
  | TargetWithTargetRowFocusedParams
  | WorksectionsParams
  | WorksectionsExpandedParams
  | WorksectionExpandedWithOrderParams
  | OrderWithInvoiceFocusedParams
  | OrderWithActualCostFocusedParams;

export const generateUrl = (urlParams: {
  route: UrlParams['route'];
  [x: string]: string | number | boolean | undefined;
}): string => {
  const urlPattern = urlParams.route;
  const path = generatePath(urlPattern, urlParams);

  return path;
};

type GetRouteParams<Route extends UrlParams['route']> = Omit<
  Extract<UrlParams, { route: Route }>,
  'route'
>;

export function useParams<Route extends UrlParams['route']>(
  _: Route
): GetRouteParams<Route> {
  return reactRouterDom.useParams<any>();
}
