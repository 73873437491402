import React from 'react';
import * as RI from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';

import { getRevenueUpdateRequest } from '../../../../store/reducers/revenue/updateRevenue';

import { requestRevenueUpdate } from '../../../../store/actions/revenue';

import { DatePicker } from '../../../../components/DatePickerInput';

import { checkDueDate, dayDiff } from '../../../../utils/general';
import { useDebounce } from '../../../../utils/hooks';
import * as remoteData from '../../../../utils/remoteData';

import { IconArrowRight, IconInvalid } from '../../../../assets/svg';

import { useParams, routes } from '../../../../routes';

type BillingDateProps = {
  date: Date | string;
  previousDate: Date | string | null;
  isBilled: boolean;
  revenueId: string;
};

const BillingDate = ({
  date,
  previousDate,
  isBilled,
  revenueId,
}: BillingDateProps) => {
  const requestId = `billingDate-${revenueId}`;
  const { viewMode, projectId } = useParams(routes.REVENUE);
  const requestState = useSelector(getRevenueUpdateRequest(requestId));

  const dispatch = useDispatch();

  // Use our custom debounce hook to create a debounced function to update
  // backend and parent state. This gets executed when it has not been called
  // again for 1 second or when the component unmounts.
  const debouncedUpdate = useDebounce((billingDate: Date | string) => {
    dispatch(
      requestRevenueUpdate(
        { billingDate },
        { revenueId, requestId, projectId },
        true
      )
    );
  }, 1000);

  const updateRevenue = (billingDate: Date | string) => {
    debouncedUpdate(billingDate);
  };

  const daysDelayed = dayDiff(date, previousDate);

  if (viewMode !== 'edit' || isBilled) {
    return (
      <>
        {daysDelayed !== 0 && previousDate ? (
          <>
            <DateChangeSpan daysDelayed={daysDelayed}>
              <RI.FormattedDate value={previousDate} />
            </DateChangeSpan>
            <StyledArrowIcon fill="black" />
          </>
        ) : null}
        {checkDueDate(date) < 0 && !isBilled ? <StyledInvalidIcon /> : null}
        <RI.FormattedDate value={date} />
      </>
    );
  }

  return (
    <StyledWrapper requestState={requestState.kind}>
      <DatePicker
        name="billingDate"
        date={new Date(date)}
        disabled={viewMode !== 'edit' || isBilled}
        onDayChange={(val) => updateRevenue(val)}
        contentContainer={StyledContainer}
      />
    </StyledWrapper>
  );
};

type StyledWrapperProps = {
  requestState: remoteData.RemoteData<unknown>['kind'];
};

const StyledWrapper = styled.div<StyledWrapperProps>`
  /* stylelint-disable selector-max-type -- TODO Could maybe be refactored,
  but might be a case where it's easiest to style a third party library component
  with a type selector */
  input {
    width: 100%;
    text-align: center;
    ${({ requestState }) =>
      ({
        NotAsked: '',
        Loading: 'color: blue;',
        Failure: 'color: red;',
        Success: '',
      })[requestState]}
  }
`;

type StyledContainerProps = {
  invalid: boolean;
};

const StyledContainer = styled.div<StyledContainerProps>``;

const StyledInvalidIcon = styled(IconInvalid)`
  margin-right: ${({ theme }) => theme.margin[8]};
  width: ${({ theme }) => theme.margin[16]};
  height: ${({ theme }) => theme.margin[16]};
`;

type DateChangeSpanProps = {
  daysDelayed: number;
};

const DateChangeSpan = styled.span<DateChangeSpanProps>`
  margin-right: ${({ theme }) => theme.margin[4]};
  color: ${({ daysDelayed, theme }) =>
    daysDelayed > 0 ? theme.color.negativeRed : theme.color.positiveGreen};
`;

const StyledArrowIcon = styled(IconArrowRight)`
  margin-right: ${({ theme }) => theme.margin[4]};
`;

export default BillingDate;
