import { APIUpdatedEntities } from '../../types/api';

import { ActionTypes as Action } from '../actionTypes';

import { AppState } from '.';

type UpdatedEntitiesAction = Exclude<
  Extract<Action, { type: Action['type']; payload: APIUpdatedEntities }>,
  | { type: 'SELECT_ARRIVAL_ROWS' }
  | { type: 'TOGGLE_SELECT_INVOICE_LINES' }
  | { type: 'LINK_INVOICE_LINE_TO_ORDER_ROW' }
  | { type: 'GET_REVENUES_SUCCEEDED' }
  | { type: 'GET_ANALYSIS_GROUPS_SUCCESS' }
  | { type: 'GET_ANALYSIS_ROWS_SUCCESS' }
  | { type: 'GET_ARRIVAL_ROWS_SUCCESS' }
  | { type: 'GET_ACTUAL_COST_ATTACHMENTS_SUCCESS' }
  | { type: 'GET_ACTUAL_COST_ATTACHMENT_FILES_SUCCESS' }
  | { type: 'GET_ORDERS_SUCCESS' }
  | { type: 'GET_ORDER_CHAT_MESSAGES_SUCCESS' }
  | { type: 'GET_ORDER_ROWS_SUCCESS' }
  | { type: 'GET_ORDER_ROWS_FOR_ANALYSIS_ROW_SUCCESS' }
  | { type: 'GET_ORDER_ROWS_FOR_WORK_PACKAGE_SUCCESS' }
  | { type: 'GET_TOPICS_BY_ORDER_ID_SUCCESS' }
  | { type: 'GET_TOPICS_BY_WORK_PACKAGE_ID_SUCCESS' }
  | { type: 'GET_TARGET_ROWS_SUCCESS' }
  | { type: 'GET_MANUAL_ENTRIES_DIMENSIONS_SUCCESS' }
  | { type: 'GET_MANUAL_ENTRIES_VALID_ITEM_SUCCESS' }
  | { type: 'GET_WORK_PACKAGES_SUCCESS' }
  | { type: 'GET_WORK_PACKAGE_GROUPS_SUCCESS' }
  | { type: 'GET_ACTUAL_COSTS_SUCCESS' }
  | { type: 'GET_ACTUAL_COST_LINES_SUCCESS' }
  | { type: 'GET_INVOICE_HEADERS_SUCCESS' }
  | { type: 'GET_TASKS_BY_ORDER_ID_SUCCESS' }
  | { type: 'GET_INVOICE_LINES_SUCCESS' }
  | { type: 'GET_SNAPSHOTS_SUCCESS' }
  | { type: 'GET_TARGET_ROWS_FOR_PROJECT_SUCCESS' }
  | { type: 'GET_TARGET_ROWS_FOR_ANALYSIS_ROW_SUCCESS' }
  | { type: 'GET_TARGET_ROWS_HIERARCHIES_SUCCESS' }
  | { type: 'GET_TOPICS_BY_PROJECT_ID_SUCCESS' }
  | { type: 'GET_PROJECT_TIMELINES_SUCCESS' }
  | { type: 'GET_WORK_PACKAGE_SCHEDULED_TASKS_SUCCESS' }
  | { type: 'GET_WORK_PACKAGE_GROUP_TIMELINES_SUCCESS' }
  | { type: 'GET_WORK_PACKAGE_TIMELINES_SUCCESS' }
  | { type: 'GET_WORK_PACKAGE_RECEIVED_TIMELINE_ENTRIES_SUCCESS' }
  | { type: 'UI_STATE_TARGET_VIEW_SEARCH' }
  | { type: 'GET_INVOICE_HEADERS_FOR_ANALYSIS_ROW_SUCCESS' }
  | { type: 'GET_ACTUAL_COSTS_FOR_ANALYSIS_ROW_SUCCESS' }
  | { type: 'GET_ARRIVAL_ROWS_FOR_ANALYSIS_ROW_SUCCESS' }
  | { type: 'GET_PAYMENT_PROGRAM_ROW_GROUPS_SUCCESS' }
  | { type: 'GET_PRESETTLED_INVOICE_ROWS_SUCCESS' }
  | { type: 'GET_PROJECT_SUCCESS' }
  | { type: 'GET_NEXTGEN_SCHEDULE_DATA_SUCCESS' }
>;

const updatedEntitiesActionTypes = [
  'POST_ARRIVAL_SUCCESS',
  'POST_SPREAD_ARRIVAL_ROWS_SUCCESS',
  'DELETE_ARRIVAL_ROW_SUCCESS',
  'PUT_ARRIVAL_ROW_SUCCESS',
  'PUT_ARRIVAL_ROWS_SUCCESS',

  'PUT_ACTUAL_COST_SUCCESS',
  'PUT_ACTUAL_COST_MOVE_SUCCESS',
  'PUT_ACTUAL_COST_CONVERT_SUCCESS',
  'PUT_ACTUAL_COST_LINES_CONVERT_SUCCESS',
  'PUT_ACTUAL_COST_REHANDLE_SUCCESS',

  'POST_ANALYSIS_ROW_SUCCESS',
  'PUT_ANALYSIS_ATTRIBUTE_VALUE_SUCCESS',
  'PUT_ANALYSIS_ROW_SUCCESS',
  'DELETE_ANALYSIS_ROW_SUCCESS',

  'POST_ORDER_ROW_ANALYSIS_LINK_SUCCESS',
  'POST_TARGET_ROW_ANALYSIS_LINK_SUCCESS',
  'POST_REVENUE_ANALYSIS_LINK_SUCCESS',
  'REMOVE_ORDER_ROW_ANALYSIS_LINK_SUCCESS',
  'REMOVE_TARGET_ROW_ANALYSIS_LINK_SUCCESS',
  'REMOVE_REVENUE_ANALYSIS_LINK_SUCCESS',

  'DELETE_ORDER_ROW_SUCCESS',
  'DELETE_MULTIPLE_ORDER_ROWS_SUCCESS',
  'DELETE_TOPIC_SUCCESS',
  'MOVE_TARGET_ROWS_TO_TOPIC_SUCCESS',
  'MOVE_TARGET_ROWS_TO_ORDER_SUCCESS',
  'MOVE_ORDER_ROWS_TO_TOPIC_SUCCESS',
  'MOVE_ORDER_ROWS_TO_ORDER_SUCCESS',

  'POST_ORDER_ROW_SUCCESS',
  'PUT_ORDER_ROW_SUCCESS',

  'POST_ORDER_SUCCESS',
  'DELETE_ORDER_SUCCESS',
  'PUT_ORDER_SUCCESS',

  'POST_TOPIC_SUCCESS',
  'PUT_TOPIC_SUCCESS',

  'POST_INVOICE_ATTACHMENT_FILES_SUCCESS',
  'PUT_CANCEL_INVOICE_HEADER_COMPLAINT_SUCCESS',
  'PUT_INVOICE_HEADER_START_CORRECTION_SUCCESS',
  'PUT_INVOICE_HEADER_FINISH_CORRECTION_SUCCESS',
  'PUT_INVOICE_HEADER_COMPLAINT_SUCCESS',
  'PUT_INVOICE_HEADER_CONVERT_SUCCESS',
  'PUT_INVOICE_LINES_CONVERT_SUCCESS',
  'PUT_INVOICE_HEADER_DECLINE_SUCCESS',
  'PUT_INVOICE_HEADER_MOVE_SUCCESS',
  'PUT_INVOICE_HEADER_SUCCESS',

  'PUT_REVENUE_SUCCEEDED',
  'POST_REVENUE_SUCCESS',
  'DELETE_REVENUE_SUCCESS',
  'DELETE_PAYMENT_PROGRAM_ROW_GROUP_SUCCESS',
  'PUT_PAYMENT_PROGRAM_ROW_GROUP_SUCCESS',
  'MOVE_PAYMENT_PROGRAM_ROWS_SUCCESS',
  'POST_PAYMENT_PROGRAM_ROW_GROUP_SUCCESS',
  'POST_PAYMENT_PROGRAM_SUCCESS',

  'PUT_WORKPACKAGE_SUCCESS',
  'POST_WORKPACKAGE_SUCCESS',
  'POST_TARGET_ROWS_SUCCESS',
  'SPLIT_TARGET_ROWS_SUCCESS',
  'DELETE_TARGET_ROW_SUCCESS',

  'POST_TASK_SUCCESS',
  'PUT_DELEGATION_STATUS_SUCCESS',

  'POST_MANUAL_ENTRIES_SUCCESS',

  'POST_SNAPSHOT_SUCCESS',

  'POST_ORDER_CHAT_MESSAGE_SUCCESS',
  'DELETE_MULTIPLE_ARRIVAL_ROWS_SUCCESS',
] as const;

type UpdatedEntitiesActionType = (typeof updatedEntitiesActionTypes)[number];

export function assertActionPayloadIsNotApiUpdatedEntities<A extends Action>(
  _: A extends UpdatedEntitiesAction ? never : A
): void | undefined {}

export function isUpdatedEntitiesActionType(
  action: Action
): action is Extract<Action, { type: UpdatedEntitiesActionType }> {
  if (
    updatedEntitiesActionTypes.includes(
      action.type as UpdatedEntitiesActionType
    )
  ) {
    return true;
  }

  return false;
}

type EndsWithFailure<S extends string> = S extends `${infer _}_FAILURE`
  ? S
  : never;
type FailureAction = Extract<Action, { type: EndsWithFailure<Action['type']> }>;
export function assertIsNotFailureAction<A extends Action>(
  _: A extends FailureAction ? never : A
): void | undefined {}

export type Selector<A> = (appState: AppState) => A;
