import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as orderRow from '../../../store/reducers/orderRow';

import { toggleStatus } from '../../../store/actions/orderRow';

import StatusPill from '../../../components/StatusPill';

const pills = {
  Contract: {
    textId: 'orderRow.status.Contract',
    colorName: 'logan',
  },
  Reserves: {
    textId: 'orderRow.status.Reserves',

    colorName: 'buttonPrimaryDisabled',
  },
  ChangeOrder: {
    textId: 'orderRow.status.ChangeOrder',
    colorName: 'wildBlueYonder',
  },
} as const;

type StatusPillProps = {
  orderRowId: string;
  isEditable: boolean;
};

const OrderRowStatusPill = ({ orderRowId, isEditable }: StatusPillProps) => {
  const dispatch = useDispatch();
  const status = useSelector(orderRow.getStatus(orderRowId));

  const toggle = () => {
    dispatch(toggleStatus(orderRowId));
  };

  return (
    <StatusPill {...pills[status]} onClick={toggle} isClickable={isEditable} />
  );
};

export default OrderRowStatusPill;
