import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';

import styled, { css } from 'styled-components';

import { IconCloseWhite } from '../../assets/svg';

import { IconButton } from '../Buttons';
import { ModalOverlay, ModalContent } from './Components';

type Props = {
  children: React.ReactNode;
  ariaLabelledBy?: string;
  onClose: () => void;
  blockEscapeKey?: boolean;
};

const Modal = ({
  children,
  ariaLabelledBy,
  onClose,
  blockEscapeKey,
}: Props) => {
  // Disable body scrolling when modal is visible.
  useEffect(() => {
    const originalStyle = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  // Close modal if user presses ESC key
  const onKeyPress = (e: KeyboardEvent) => {
    if (e.code === 'Escape' && !blockEscapeKey) {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onKeyPress);

    return () => {
      window.removeEventListener('keyup', onKeyPress);
    };
  });

  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  const modalContent = (
    <ModalOverlay onMouseDown={onClose}>
      <ModalContent
        role="dialog"
        onClick={stopPropagation}
        onMouseDown={stopPropagation}
        aria-labelledby={ariaLabelledBy}
      >
        <FocusLock>
          <CloseButton
            onClick={onClose}
            type="button"
            icon={IconCloseWhite}
            tabIndex={0}
          />
          {children}
        </FocusLock>
      </ModalContent>
    </ModalOverlay>
  );

  return ReactDOM.createPortal(modalContent, document.body);
};

type ContentProps = {
  noMaxHeight?: boolean;
};

export const Content = styled.div<ContentProps>`
  padding: ${(props) =>
    `${props.theme.margin[16]} ${props.theme.margin[16]} ${props.theme.margin[16]} ${props.theme.margin[32]}`};

  ${(props) => (props.noMaxHeight ? '' : `max-height: 514px;`)}

  min-height: 514px;

  display: flex;
  flex-direction: column;

  flex: 1;

  background: ${(props) => props.theme.color.white};
`;

type ContainerProps = {
  noShadow?: boolean;
};

export const Container = styled.div<ContainerProps>`
  border-radius: ${({ theme }) => theme.radius.large};
  ${({ noShadow }) =>
    !noShadow
      ? css`
          box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
        `
      : null}

  min-width: 484px;
  width: 50vw;

  display: flex;
  flex-flow: column nowrap;

  overflow: hidden;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const Header = styled.div`
  border-radius: ${({ theme }) => theme.radius.medium}
    ${({ theme }) => theme.radius.medium} 0 0;

  padding: ${(props) => `${props.theme.margin[16]} ${props.theme.margin[32]}`};

  max-height: 68px;

  background-color: ${(props) => props.theme.color.modalTitleBarBackground};

  color: ${({ theme }) => theme.color.white};
`;

export const HeaderText = styled.h2`
  font-family: 'Graphik', sans-serif;
  font-weight: 600;
  font-size: 16px;
`;

export const Footer = styled.div`
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 ${({ theme }) => theme.radius.medium}
    ${({ theme }) => theme.radius.medium};

  padding: ${(props) =>
    `${props.theme.margin[16]} ${props.theme.margin[16]} ${props.theme.margin[16]} ${props.theme.margin[32]}`};

  display: flex;
  justify-content: flex-end;
  align-items: center;

  background: ${(props) => props.theme.color.white};
`;

export const FilterTextInput = styled.input`
  margin: ${(props) => `${props.theme.margin[16]} 0 `};

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.dropdownBorder};

  padding: ${(props) => `0 ${props.theme.margin[16]}`};

  min-height: 40px;

  background: ${({ theme }) => theme.color.graphiteB96A};

  font-size: 0.75rem;
  color: ${(props) => `${props.theme.color.pitch}`};
  ::placeholder {
    opacity: 0.5;
    color: ${(props) => `${props.theme.color.pitch}`};
  }
`;

export default Modal;
