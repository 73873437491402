import React from 'react';

import styled from 'styled-components';
import { v1 as uuid } from 'uuid';

import { IconCheckmarkCheckbox } from '../../assets';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;

  width: 0;
  height: 0;

  opacity: 0;

  overflow: hidden;
`;

/* stylelint-disable selector-max-class -- Another component name is classified as a classname selector by parser */
const StyledCheckbox = styled.div<{
  checked?: boolean;
  variant: string;
}>`
  position: relative;

  border: 1px solid ${(props) => props.theme.color.pitch};

  width: 1rem;
  height: 1rem;

  display: inline-block;

  cursor: pointer;

  ${/* sc-selector */ HiddenCheckbox}:hover + &,
  &:hover {
    border-width: 2px;
  }

  ${/* sc-selector */ HiddenCheckbox}:checked + & {
    border-color: ${(props) => props.theme.color.pitch};
    background: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.color.lilac
        : props.theme.color.pitch};
  }

  ${/* sc-selector */ HiddenCheckbox}:focus + & {
    outline: none;
    outline-offset: none;
    box-shadow:
      0 0 0 2px ${(props) => props.theme.color.white},
      0 0 0 4px ${(props) => props.theme.color.blue};
  }

  ${/* sc-selector */ HiddenCheckbox}:hover:checked + &,
  ${/* sc-selector */ HiddenCheckbox}:checked + &:hover {
    border-color: ${(props) => props.theme.color.graphiteB38};
    background-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.color.lilac
        : props.theme.color.graphiteB38};
  }

  ${/* sc-selector */ HiddenCheckbox}:checked + &::after {
    width: 100%;
    height: 100%;

    display: block;

    content: '';
    background: transparent url(${IconCheckmarkCheckbox}) no-repeat scroll;
    background-position: 50% 50%;
  }
  ${/* sc-selector */ HiddenCheckbox}:disabled + & {
    border-color: ${(props) => props.theme.color.graphiteB76};
    border-width: 1px;
    background: ${(props) => props.theme.color.graphiteB76};
    cursor: default;
  }
  ${/* sc-selector */ HiddenCheckbox}:disabled:hover + & {
    border-color: ${(props) => props.theme.color.graphiteB76};
    background: ${(props) => props.theme.color.graphiteB76};
  }
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
`;

/**
 * Checkbox is generally one common input element in forms.
 * In SDPay, although we have many text inputs and forms, we don't have
 * many checkboxes in the forms asking yes/no questions
 * ("Does this order have something? Y/N")
 *
 * In SDPay, we use checkboxes mostly to indicate choosing or selecting
 * elements or components (e.g. rows, invoices or actual costs).
 * Therefore this Checkbox component is built to be used as
 * a selection component, and not as a form input component (for example,
 * this component doesn't have styles for a required state, and you
 * cannot pass text to the label, except as an aria-label).
 *
 */
type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  variant?: string;
};

const Checkbox = (props: CheckboxProps) => {
  const { 'aria-label': ariaLabel, variant, type: _, ...inputProps } = props;
  const inputId = `checkbox-${uuid()}`;

  const stopPropagation = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const clickHandle = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <StyledLabel
      htmlFor={inputId}
      aria-label={ariaLabel}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <HiddenCheckbox
        id={inputId}
        type="checkbox"
        {...inputProps}
        onKeyDown={stopPropagation}
        onClick={clickHandle}
      />
      <StyledCheckbox
        variant={variant ?? 'primary'}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </StyledLabel>
  );
};

export default Checkbox;
