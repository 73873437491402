import React from 'react';
import { useHistory } from 'react-router-dom';

import { ColumnDef } from '@tanstack/react-table';
import Big from 'big.js';
import styled from 'styled-components';

import Txt from '../../../components/Txt';
import TargetRowDescription from '../components/TargetTable/TableCells';
import {
  ReferenceNoTh,
  TargetTableSearchInput,
} from '../components/TargetTable/Th';

import * as big from '../../../utils/big';
import { isClickOrKeyboardSelection } from '../../../utils/mouseOrKeyInteraction';

import { generateUrl, routes } from '../../../routes';

import { TargetRowOrTargetRowHierarchyEntry } from './useTargetViewData';

const textIdHead = 'target.table.header.';

export default function useTargetViewColumns() {
  const history = useHistory();

  const onClickOrKeyboardSelection = (
    e: React.MouseEvent | React.KeyboardEvent,
    callback: () => void
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      callback();
    }
  };

  const onClickOrder = React.useCallback(
    (projectId: string, orderId: string | null, topicId: string | null) => {
      if (!orderId || !topicId) {
        return;
      }

      history.push(
        generateUrl({
          route: routes.ORDER_WITH_OPEN_TOPIC,
          projectId,
          orderId,
          topicId,
          showTargetRows: 'showTarget',
        })
      );
    },
    [history]
  );

  const onClickWorkPackage = React.useCallback(
    (projectId: string, workPackageId: string | undefined | null) => {
      if (!workPackageId) {
        return;
      }

      const nextUrl = generateUrl({
        route: routes.WORKSECTION_EXPANDED,
        projectId,
        workPackageId,
      });

      history.push(nextUrl);
    },
    [history]
  );

  const columns = React.useMemo<
    ColumnDef<TargetRowOrTargetRowHierarchyEntry>[]
  >(
    () => [
      {
        accessorKey: 'referenceNo',
        header: ({ table }) => {
          return <ReferenceNoTh table={table} />;
        },
        cell: ({ getValue }) => {
          const value =
            getValue<TargetRowOrTargetRowHierarchyEntry['referenceNo']>();

          return <div>{value}</div>;
        },
        size: 70,
        meta: {
          align: 'left',
        },
      },
      {
        accessorKey: 'description',
        header: ({ table }) => {
          return (
            <StyledDescriptionDiv>
              <Txt id={`${textIdHead}description` as const} component="b" />
              <TargetTableSearchInput table={table} />
            </StyledDescriptionDiv>
          );
        },
        cell: ({ row }) => {
          return <TargetRowDescription row={row} />;
        },
        size: 250,
        meta: {
          align: 'left',
          borderWidthRight: 2,
        },
      },
      {
        accessorKey: 'quantity',
        header: () => {
          return <Txt id={`${textIdHead}quantity` as const} component="b" />;
        },
        cell: (info) => {
          const value =
            info.getValue<TargetRowOrTargetRowHierarchyEntry['quantity']>();

          return big.amountFormat(value ?? new Big(0));
        },
        size: 50,
        meta: {
          align: 'right',
          borderWidthRight: 1,
        },
      },
      {
        accessorKey: 'unit',
        header: () => {
          return <Txt id={`${textIdHead}unit` as const} component="b" />;
        },
        cell: (info) => {
          const value =
            info.getValue<TargetRowOrTargetRowHierarchyEntry['unit']>();

          return value;
        },
        size: 30,
        meta: {
          align: 'left',
          borderWidthRight: 1,
        },
      },
      {
        accessorKey: 'unitPrice',
        header: () => {
          return <Txt id={`${textIdHead}unitPrice` as const} component="b" />;
        },
        cell: (info) => {
          const value =
            info.getValue<TargetRowOrTargetRowHierarchyEntry['unitPrice']>();

          return big.amountFormat(value ?? new Big(0));
        },
        size: 50,
        meta: {
          align: 'right',
          borderWidthRight: 1,
        },
      },
      {
        accessorKey: 'target',
        header: () => {
          return <Txt id={`${textIdHead}target` as const} component="b" />;
        },
        cell: (info) => {
          const value =
            info.getValue<TargetRowOrTargetRowHierarchyEntry['target']>();

          return big.priceFormat(value ?? new Big(0));
        },
        size: 70,
        meta: {
          align: 'right',
          borderWidthRight: 1,
        },
      },
      {
        accessorKey: 'orderCodeName',
        header: () => {
          return <Txt id={`${textIdHead}order` as const} component="b" />;
        },
        cell: ({ getValue, row }) => {
          const value =
            getValue<TargetRowOrTargetRowHierarchyEntry['orderCodeName']>();

          const { topicId, projectId, orderId } = row.original;

          return value && topicId ? (
            <StyledPointerSpan
              tabIndex={0}
              onKeyDown={(e) =>
                onClickOrKeyboardSelection(e, () =>
                  onClickOrder(projectId, orderId, topicId)
                )
              }
              onClick={(e) =>
                onClickOrKeyboardSelection(e, () =>
                  onClickOrder(projectId, orderId, topicId)
                )
              }
            >
              {value}
            </StyledPointerSpan>
          ) : null;
        },
        size: 80,
        meta: {
          align: 'left',
          borderWidthRight: 1,
        },
      },
      {
        accessorKey: 'workSectionCodeName',
        header: () => {
          return <Txt id={`${textIdHead}workSection` as const} component="b" />;
        },
        cell: ({ getValue, row }) => {
          const value =
            getValue<
              TargetRowOrTargetRowHierarchyEntry['workSectionCodeName']
            >();

          const { projectId, workPackageId } = row.original;

          return value ? (
            <StyledPointerSpan
              tabIndex={0}
              onKeyDown={(e) =>
                onClickOrKeyboardSelection(e, () =>
                  onClickWorkPackage(projectId, workPackageId)
                )
              }
              onClick={(e) =>
                onClickOrKeyboardSelection(e, () =>
                  onClickWorkPackage(projectId, workPackageId)
                )
              }
            >
              {value}
            </StyledPointerSpan>
          ) : null;
        },
        size: 80,
        meta: {
          align: 'left',
        },
      },
    ],
    [onClickOrder, onClickWorkPackage]
  );

  return columns;
}

const StyledPointerSpan = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;
`;

const StyledDescriptionDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
