import { DefaultTheme } from 'styled-components';

// All sizes are relative to browser's default font size, which is
// normally 16px. Therefore, 0.875rem for example is 14px. For 8-width grid,
// being relative to 16px makes widths very precise.

const theme: DefaultTheme = {
  sizes: {
    sitepayLogoHeight: '4rem',
    sitepayLogoWidth: '4rem',
    headerHeight: '3.5rem',
    iconButtonLarge: '3rem',
    iconButtonSmall: '1rem',
    navItemSize: '5.125rem',
    sideNavWidth: '5.5rem',
    sidebarButtonPadding: '1.5rem',
    smallPadding: '0.75rem',
  },
  fontSize: {
    miniscule: '0.5rem',
    tiny: '0.625rem',
    small: '0.75rem',
    base: '0.875rem',
    button: '1rem',
    textButton: '0.875rem',
    h1: '1.5rem',
    h2: '1rem',
    h3: '1rem',
  },
  margin: {
    // Prefer not using 4px or 2px margin as it's not divisble by 8. However,
    // sometimes it's necessary as 8 is way too much.
    2: '0.125rem',
    4: '0.25rem',
    8: '0.5rem',
    10: '0.625rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    72: '4.5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    176: '11rem',
  },
  radius: {
    tiny: '0.125rem', // 2px;
    small: '0.25rem', // 4px;
    medium: '0.5rem', // 8px
    large: '1rem', // 16px;
    huge: '1.5rem', // 24px
  },
  color: {
    // Colors that did not have a match in the design system. FIXME
    buttonPrimaryDisabled: '#C4C4C4',
    toolbarHighlight: '#D8CDDE',
    inputBorder: '#E6E6E6',
    infoNotification: '#4285F4',
    errorNotification: '#CF1627',
    sidebarBackground: '#450866',
    sidebarBackgroundHighlight: '#48206A',
    sidebarNotification: '#51017a',
    sidebarFadedText: '#c5b4ce',
    negativeRed: '#BB1616',
    positiveGreen: '#44a26c',
    lightPositiveGreen: '#97ceaf',
    notificationContent: '#9B51E0',
    lightBlue: '#bed5f0',

    //
    modalTitleBarBackground: '#3D055C',
    modalTitleBarText: '#FFF',
    primaryRowBackground: '#F4F4F4',
    rowBorder: '#EBEBEB',
    rowBorderActive: '#A0A0A0',
    dropdownBorder: '#E2E2E2',
    dropdownBg: '#FAFAFA',
    indicatorBackground: '#3D055C',
    embedBackground: '#323639',

    pitch: '#2C2A29', // SD DARK GREY
    graphite: '#494947',
    graphiteB38: '#62615F',
    graphiteB48: '#7A7977',
    graphiteB57: '#92918E',
    graphiteB76: '#C3C2BE',
    graphiteB86: '#DCDAD5',
    graphiteB91: '#E8E6E1',
    graphiteB96A: '#F5F3EF', // there were two b96 in the design system, I asked
    graphiteB96B: '#F6F5F1', // a question about it. Named now as A and B. FIXME
    graphiteB97: '#F8F7F4',
    graphiteB98: '#FBFAF8',
    graphiteB99: '#FDFCFB',
    indigo: '#500778',
    purple: '#601884',
    darkpurple: '#3D055C',
    backgroundTop: '#563372',
    lilac: '#A883BC',
    apple: '#5AB535',
    mantis: '#8BCB72',
    moss: '#ACDA9A',
    beryl: '#CDE9C2',
    pink: '#F5DADF',
    yellow: '#FCE300',
    orange: '#FFB81C',
    aquamarine: '#6FCF97',
    forest: '#2C5234',
    red: '#FA1228',
    cyan: '#07B9F5',
    blue: '#2F80ED',
    denim: '#2D9CDB',
    aqua: '#79DFEC',
    amethyst: '#9B51E0',
    orchid: '#BB6BD9',
    white: '#FFFFFF',
    logan: '#A1A0C7',
    wildBlueYonder: '#8584AE',
    amethystSmoke: '#A28BAE',
    whisper: '#ECE6EF',
    graySuit: '#D6CFD9',
    mercury: '#E9E9E9',
    black: '#000000',
    darkOrange: '#D55900',

    // Colors from graph design
    chartAccepted: '#D95970',
    chartPlanned: '#44A26C',

    // colors from the design system
    'M3/sys/light/primary': '#6750A4',
    'M3/sys/light/on-primary': '#FFFFFF',
    'M3/white': '#FFFFFF',
    'M3/sys/light/secondary-container': '#F6EDFF',
    'M3/sys/light/on-secondary-container': '#1D192B',
    'M3/sys/light/outline': '#79747E',
    'M3/sys/light/error': '#B3261E',
    'M3/sys/dark/secondary': '#CCC2DC',
    'M3/sys/light/on-surface-variant': '#49454F',
    'M3/sys/light/surface-variant': '#E7E0EC',
    'M3/sys/dark/error-container': '#8C1D18',
    'M3/ref/primary/primary60': '#9A82DB',
    'M3/ref/primary/primary70': '#B69DF8',
    'M3/ref/primary/primary90': '#EADDFF',
    'M3/ref/primary/primary95': '#F6EDFF',
    'M3/ref/primary/primary99': '#FFFBFE',
    'M3/ref/neutral/neutral90': '#E6E1E5',
    'M3/sys/light/inverse-on-surface': '#F4EFF4',
    'M3/surfaces/light/surface': '#F7F2F9',
    'M3/ref/neutral/neutral50': '#787579',
    'M3/ref/neutral/neutral70': '#AEAAAE',

    statusColors: {
      started: '#6E9CF2',
      done: '#8DDE81',
      delayed: '#F4B76B',
      planned: '#F8F8F8',
      readyToStart: '#E4EFFF',
      obstacle: '#F88080',
    },
  },
};

export default theme;
