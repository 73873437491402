import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import {
  RawAPIUpdatedEntities,
  APIUpdatedEntities,
  PutInvoiceLinesConvertBody,
} from '../../types/api';
import { ID } from '../../types/general';
import { mapRawUpdatedEntities } from '../../types/mappers';

import { ExtractActionTypes, makeAction } from '../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
  PUT,
} from '../../utils/api';
import { dateString, bigString } from '../../utils/decoders';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import {
  InvoiceLine,
  selectOrderInvoiceLinesRequests,
} from '../reducers/invoiceLine';

const actionCreators = {
  ...makeAction('getInvoiceLinesStarted')<{ orderId: string }>(),
  ...makeAction('getInvoiceLinesFailure')<{
    orderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getInvoiceLinesSuccess')<{
    orderId: string;
    invoiceLines: InvoiceLine[];
  }>(),
  ...makeAction('putInvoiceLinesConvertStarted')<{
    requestId: string;
  }>(),
  ...makeAction('putInvoiceLinesConvertFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('putInvoiceLinesConvertSuccess')<
    {
      requestId: string;
    } & APIUpdatedEntities
  >(),
  ...makeAction('moveInvoiceLineSuccess')<{
    onClick: () => void;
    topicName: string | undefined;
  }>(),
};

export type InvoiceLineAction = ExtractActionTypes<typeof actionCreators>;
export const {
  getInvoiceLinesStarted,
  getInvoiceLinesSuccess,
  getInvoiceLinesFailure,
  putInvoiceLinesConvertStarted,
  putInvoiceLinesConvertFailure,
  putInvoiceLinesConvertSuccess,
  moveInvoiceLineSuccess,
} = actionCreators;

const apiInvoiceLineType = t.exact(
  t.type({
    id: t.string,
    purchaseInvoiceLineExternalId: t.string,
    rowIndex: t.number,
    purchaseInvoiceExternalId: t.string,
    orderRowId: t.union([t.string, t.null]),
    arrivalRowId: t.union([t.string, t.null]),
    lineType: t.string,
    purchaseOrderNumber: t.union([t.string, t.null]),
    purchaseOrderLineNumber: t.string,
    productCode: t.union([t.string, t.null]),
    productName: t.union([t.string, t.null]),
    quantity: bigString,
    unit: t.string,
    netPrice: bigString,
    netTotal: bigString,
    grossPrice: bigString,
    grossTotal: bigString,
    taxPercent: bigString,
    taxSum: bigString,
    discountSum: bigString,
    discountPercent: bigString,
    additionalCostName: t.union([t.string, t.null]),
    additionalCostKeyword: t.union([t.string, t.null]),
    createdAt: dateString,
    updatedAt: dateString,
  })
);

export async function toInvoiceLines(u: unknown): Promise<InvoiceLine[]> {
  const apiInvoiceLines = await tPromise.decode(t.array(apiInvoiceLineType), u);

  return apiInvoiceLines;
}

async function getInvoiceLinesForOrder(orderId: ID): Promise<InvoiceLine[]> {
  const response = await GET(`v1/orders/${orderId}/purchase-invoice-lines`);

  return toInvoiceLines(response);
}

export const fetchInvoiceLinesForOrder = (orderId: ID): Thunk =>
  createAsyncThunk(getInvoiceLinesForOrder, {
    args: [orderId],
    isPending: flow(
      selectOrderInvoiceLinesRequests(orderId),
      remoteData.isLoading
    ),
    initialAction: getInvoiceLinesStarted({ orderId }),
    successActionCreator: (invoiceLines) =>
      getInvoiceLinesSuccess({ orderId, invoiceLines }),
    failureActionCreator: (error) =>
      getInvoiceLinesFailure({
        orderId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

export const convertInvoiceLines =
  (
    invoiceHeaderId: ID,
    requestId: string,
    body: PutInvoiceLinesConvertBody
  ): Thunk =>
  (dispatch, _) => {
    dispatch(putInvoiceLinesConvertStarted({ requestId }));

    PUT<RawAPIUpdatedEntities>(
      `v1/purchase-invoice-headers/${invoiceHeaderId}/invoice-lines-convert`,
      body
    )
      .then(mapRawUpdatedEntities)
      .then(
        (updatedEntities) => {
          dispatch(
            putInvoiceLinesConvertSuccess({
              requestId,
              ...updatedEntities,
            })
          );
        },
        (error) => {
          dispatch(
            putInvoiceLinesConvertFailure({
              requestId,
              error: apiErrorHandlingWithDecode(error),
            })
          );
        }
      );
  };

export const moveInvoiceLineNotification =
  (onClick: () => void, topicName?: string): Thunk =>
  (dispatch, _) => {
    dispatch(moveInvoiceLineSuccess({ onClick, topicName }));
  };
