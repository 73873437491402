import React from 'react';

import { orderBy } from 'lodash';
import styled from 'styled-components';

import CheckboxComponent from '../../../../components/Input/Checkbox';
import Txt from '../../../../components/Txt';

import { useDebouncedValue } from '../../../../utils/hooks';
import { searchFilter } from '../../../../utils/search';

import { IconExpandPlus, IconCollapseMinus } from '../../../../assets/svg';

import {
  ExpandCollapseButton,
  ExtendedScheduleWorkPackageType,
} from '../NewScheduleTree';
import { TeamColorBox } from '../NewScheduleTreeRow';

type WorkPackageListProps = {
  filter: string;
  workPackages: ExtendedScheduleWorkPackageType[];
  onSelect: (ids: string[]) => void | undefined;
  selectedIds: string[];
  selectedIdsViaWorkSection: string[];
};

const WorkPackageList = ({
  filter,
  workPackages,
  onSelect,
  selectedIds,
  selectedIdsViaWorkSection,
}: WorkPackageListProps) => {
  const workPackageRef = React.useRef<any>();
  const [isScrolled, setIsScrolled] = React.useState(false);

  const [closedWorkPackages, setClosedWorkPackages] = React.useState<string[]>(
    []
  );

  React.useEffect(() => {
    if (isScrolled) {
      return;
    }

    if (workPackageRef.current) {
      workPackageRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
      setIsScrolled(true);
    }
  }, [workPackageRef, isScrolled]);

  let filteredWorkPackages: ExtendedScheduleWorkPackageType[] = [];

  const textFilteredWorkPackages = searchFilter(
    workPackages,
    useDebouncedValue(filter, 500),
    ['name', 'virtualSpaceName', 'workSectionClassName', 'teamName']
  );

  const filteredWorkPackagesWithChildren = workPackages.filter((wp) => {
    const filteredIds = textFilteredWorkPackages.map((row) => row.id);

    const parentsAndSelf = [wp.id, ...wp.parents];

    return parentsAndSelf.some((parent) => filteredIds.includes(parent));
  });

  const parentsToBeShown = filteredWorkPackagesWithChildren.reduce(
    (acc, workPackage) => {
      return [...acc, ...workPackage.parents];
    },
    [] as string[]
  );

  const uniqueParentsToBeShown = [...new Set(parentsToBeShown)];

  const filteredWorkPackageIds = filteredWorkPackagesWithChildren.map(
    (row) => row.id
  );

  const filteredWorkPackagesWithParents = workPackages.filter(
    (wp) =>
      uniqueParentsToBeShown.includes(wp.id) ||
      filteredWorkPackageIds.includes(wp.id) ||
      selectedIds.includes(wp.id)
  );

  filteredWorkPackages = orderBy(
    filteredWorkPackagesWithParents,
    ['order'],
    ['asc']
  );

  const filteredTopLevelWorkPackages = filteredWorkPackages.filter(
    (workPackage) => workPackage.parent_id === null
  );

  const toggleClosedWorkPackages = (id: string) => {
    if (closedWorkPackages.includes(id)) {
      setClosedWorkPackages(closedWorkPackages.filter((wp) => wp !== id));
    } else {
      setClosedWorkPackages([...closedWorkPackages, id]);
    }
  };

  const onSelectAll = () => {
    const selectedWorkPackageIds = filteredWorkPackages.map(
      (workPackage) => workPackage.id
    );

    onSelect(selectedWorkPackageIds);
  };

  const headerCheckboxChecked =
    selectedIds.length >= filteredWorkPackages.length &&
    filteredWorkPackages
      .map((row) => row.id)
      .every((id) => selectedIds.includes(id));

  return (
    <Container>
      <Header>
        <CheckboxComponent
          checked={headerCheckboxChecked}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              e.preventDefault();
              onSelectAll();
            }
          }}
          onChange={onSelectAll}
        />
        <HeaderNameSpan width="40%">
          <Txt id="worksection.workpackage.scheduleWorkPackage" />
        </HeaderNameSpan>
        <HeaderNameSpan width="20%">
          <Txt id="worksection.workpackage.location" />
        </HeaderNameSpan>
        <HeaderNameSpan width="20%">
          <Txt id="common.workSectionClass" />
        </HeaderNameSpan>
        <HeaderNameSpan width="20%">
          <Txt id="worksection.workpackage.team" />
        </HeaderNameSpan>
      </Header>
      {filteredTopLevelWorkPackages.map((workPackage) => {
        return (
          <WorkPackageItem
            key={`workPackage-item-${workPackage.id}`}
            onSelect={onSelect}
            workPackage={workPackage}
            filteredWorkPackages={filteredWorkPackages}
            toggleClosedWorkPackages={toggleClosedWorkPackages}
            closedWorkPackages={closedWorkPackages}
            selectedIdsViaWorkSection={selectedIdsViaWorkSection}
            selectedIds={selectedIds}
            isOpen={!closedWorkPackages.includes(workPackage.id)}
          />
        );
      })}
    </Container>
  );
};

export default WorkPackageList;

type WorkPackageNameProps = {
  depth?: number;
  width?: string;
};

const WorkPackageNameSpan = styled.span<WorkPackageNameProps>`
  margin-left: ${({ theme: { margin } }) => margin[8]};

  padding-left: ${({ depth }) => (depth ?? 0) + 1}rem;

  width: ${({ width }) => width ?? '30%'};

  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;
`;

const Container = styled.div`
  position: relative;

  margin-bottom: 2rem;

  max-height: 70vh;

  display: flex;
  flex-direction: column;

  overflow-y: scroll;
`;

const Header = styled.header`
  position: sticky;
  top: 0;

  border-bottom: 1px solid ${({ theme: { color } }) => color.graphite};

  padding: ${(props) => props.theme.margin[8]}
    ${(props) => props.theme.margin[16]};

  display: flex;
  flex-direction: row;

  background-color: white;

  z-index: 100;
`;

const HeaderNameSpan = styled(WorkPackageNameSpan)`
  font-weight: 700;
`;

const WorkPackage = styled.div`
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid ${({ theme: { color } }) => color.dropdownBorder};

  padding: ${(props) => props.theme.margin[8]}
    ${(props) => props.theme.margin[16]};

  display: flex;
  justify-content: start;
  align-items: center;

  font-family: 'Graphik', sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
`;

const EmptyDiv = styled.div`
  height: 20px;
  width: 20px;
  display: inline-block;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: end;
`;

type WorkPackageItemProps = {
  workPackage: ExtendedScheduleWorkPackageType;
  selectedIds: string[];
  onSelect: (ids: string[]) => void;
  filteredWorkPackages: ExtendedScheduleWorkPackageType[];
  selectedIdsViaWorkSection: string[];
  toggleClosedWorkPackages: (id: string) => void;
  closedWorkPackages: string[];
  isOpen: boolean;
};

const WorkPackageItem = ({
  workPackage,
  selectedIds,
  onSelect,
  filteredWorkPackages,
  selectedIdsViaWorkSection,
  closedWorkPackages,
  toggleClosedWorkPackages,
  isOpen,
}: WorkPackageItemProps) => {
  const { id, name, depth } = workPackage;
  const children = filteredWorkPackages.filter((t) => t.parent_id === id);

  const showCollapseExpand = children.length > 0;

  return (
    <>
      <WorkPackage key={id} data-testid={`link-workPackage-div-${id}`}>
        <CheckboxComponent
          checked={selectedIds.includes(id)}
          onChange={() => onSelect([id])}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              e.preventDefault();
              onSelect([id]);
            }
          }}
          disabled={selectedIdsViaWorkSection.includes(id)}
        />
        <WorkPackageNameSpan depth={depth} width="40%">
          {showCollapseExpand ? (
            <ExpandCollapseButton
              icon={
                closedWorkPackages.includes(id)
                  ? IconExpandPlus
                  : IconCollapseMinus
              }
              customSize="12px"
              onClick={() => toggleClosedWorkPackages(id)}
            />
          ) : (
            <EmptyDiv />
          )}
          {name}
        </WorkPackageNameSpan>
        <WorkPackageNameSpan width="20%">
          {workPackage.virtualSpaceName}
        </WorkPackageNameSpan>
        <WorkPackageNameSpan width="20%">
          {workPackage.workSectionClassName}
        </WorkPackageNameSpan>
        <WorkPackageNameSpan width="20%">
          <FlexDiv>
            <TeamColorBox color={workPackage.teamColor ?? 'white'} />
            <span>{workPackage.teamName}</span>
          </FlexDiv>
        </WorkPackageNameSpan>
      </WorkPackage>
      {isOpen
        ? children.map((child) => {
            return (
              <WorkPackageItem
                key={`workPackage-item-${child.id}`}
                onSelect={onSelect}
                workPackage={child}
                filteredWorkPackages={filteredWorkPackages}
                toggleClosedWorkPackages={toggleClosedWorkPackages}
                closedWorkPackages={closedWorkPackages}
                selectedIdsViaWorkSection={selectedIdsViaWorkSection}
                selectedIds={selectedIds}
                isOpen={!closedWorkPackages.includes(child.id)}
              />
            );
          })
        : null}
    </>
  );
};
